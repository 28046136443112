define("happyfox-bi/components/widget-tile/component", ["exports", "happyfox-bi/visualizations/constant", "happyfox-bi/components/base-chart/constants", "happyfox-bi/components/compare-date-range-picker/utilities", "happyfox-bi/utilities/date"], function (_exports, _constant, _constants, _utilities, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var AUTO_DATE_FORMAT_OPTION = {
    'id': 'auto',
    'name': 'Auto'
  };
  var WIDGETS_SUPPORTING_COMPARE_DATE_RANGES = ['area_chart', 'line_chart', 'bar_chart', 'pivot_table', 'data_tile'];

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    fullscreenService: Ember.inject.service('fullscreen'),
    profileService: Ember.inject.service('profile'),
    tagName: 'section',
    classNames: ['widget-tile'],
    classNameBindings: ['classNameForFullscreen', 'isTemporaryVisualization:is-temporary'],
    isWidgetTileInFullScreen: false,
    resizedWidget: null,
    widgetModel: null,
    widgetData: null,
    isLoadingData: false,
    isPreview: false,
    isTemporaryVisualization: false,
    temporaryVisualizationId: null,
    prefetchedFiltersValueOptions: null,
    existingDateRangeData: null,
    isAnyElementInFullscreen: Ember.computed.reads('fullscreenService.isCurrentlyFullscreen'),
    isDataTile: Ember.computed.equal('widgetModel.displayType', 'data_tile'),
    dateRangePreferences: Ember.computed.reads('profileService.profile.dateRangePreferences'),
    compareDateRangePreferences: Ember.computed.reads('profileService.profile.dateRangePreferences.compare'),
    compareDateRangesList: null,
    showRawData: false,
    rawDataFilters: null,
    xAxisFieldName: Ember.computed.reads('widgetModel.displaySettings.xAxis.key'),
    hideHeader: false,
    isRequestFailure: false,
    compareDateFeature: Ember.computed.reads('featuresService.compareDateRanges'),
    showDateFormatSwitcher: Ember.computed.or('isXAxisDateTimeType', 'isXAxisDateType', 'hasDateFieldInPivotTable'),
    canShowSampleVisualizationModal: false,
    _documentClickHandler: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('prefetchedFiltersValueOptions', Ember.Object.create());
      var dateRangePreferences = this.get('dateRangePreferences');
      this.set('existingDateRangeData', {
        dateRange: dateRangePreferences.get('dateRange'),
        fromDate: dateRangePreferences.get('fromDate'),
        toDate: dateRangePreferences.get('toDate'),
        compare: dateRangePreferences.get('compare'),
        dateRangeField: this.get('widgetModel.settings.query.dateRangeField')
      });
      this.loadData(true);
      Ember.run.next(function () {
        _this._setupScrollOnFocus();
      });
    },
    willDestroyElement: function willDestroyElement() {
      var widgetInFocus = this.element.getAttribute('data-widget-in-focus');

      if (widgetInFocus === 'true') {
        document.removeEventListener('click', this._documentClickHandler);
      }
    },
    isTextTile: Ember.computed.equal('widgetModel.displayType', 'text_tile'),
    hideVisualizationActions: Ember.computed('widgetModel.settings.disableEdit', 'isPreview', {
      get: function get() {
        return this.get('widgetModel.settings.disableEdit') || this.get('isPreview');
      }
    }),
    showValueLabels: Ember.computed('widgetModel.{preferences.showValueLabels,displaySettings.showValueLabels}', 'isPreview', {
      get: function get() {
        var widgetDisplaySettings = this.get('widgetModel.displaySettings');
        var widgetPreferences = this.get('widgetModel.preferences');

        if (!this.get('isPreview')) {
          if (widgetPreferences && Ember.isPresent(widgetPreferences.get('showValueLabels'))) {
            return widgetPreferences.get('showValueLabels');
          }
        }

        return widgetDisplaySettings.showValueLabels;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    canExportRawData: Ember.computed('featuresService.exportRawData', 'isRequestFailure', 'isTextTile', {
      get: function get() {
        return this.featuresService.exportRawData && !this.isRequestFailure && !this.isTextTile;
      }
    }),
    canViewRawData: Ember.computed('featuresService.viewRawData', 'isRequestFailure', 'isTextTile', {
      get: function get() {
        return this.featuresService.viewRawData && !this.isRequestFailure && !this.isTextTile;
      }
    }),
    canCompareDateRange: Ember.computed('widgetModel.{displayType,settings.query.dateRangeField}', 'isPreview', 'compareDateFeature', 'compareDateRangePreferences', {
      get: function get() {
        var widgetType = this.get('widgetModel.displayType');
        var dateRangeField = this.get('widgetModel.settings.query.dateRangeField');
        return WIDGETS_SUPPORTING_COMPARE_DATE_RANGES.includes(widgetType) && this.get('compareDateFeature') && Ember.isPresent(this.get('compareDateRangePreferences')) && dateRangeField && !this.get('isPreview');
      }
    }),
    canToggleValueLabels: Ember.computed('widgetModel.displayType', {
      get: function get() {
        var widgetType = this.get('widgetModel.displayType');
        return _constant.WIDGETS_WITH_VALUE_LABELS.includes(widgetType);
      }
    }),
    // Options shown on widget tile when x axis field is either timestamp or date
    dateFormatOptions: Ember.computed('widgetModel.settings.query.dateRangeField', {
      get: function get() {
        var isXAxisDateTimeType = this.isXAxisDateTimeType;
        var options = Ember.A();

        if (isXAxisDateTimeType) {
          // Show format options of type Timestamp
          options = _constant.TIMESTAMP_FORMAT_OPTIONS.filter(function (option) {
            return _constants.FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS.includes(option.id);
          });
        } else {
          // Show format options of type Date
          options = _constant.DATE_FORMAT_OPTIONS.filter(function (option) {
            return _constants.FORMAT_SWITCHER_DATE_BASED_OPTIONS.includes(option.id);
          });
        }

        if (this.get('widgetModel.settings.query.dateRangeField')) {
          options.unshift(AUTO_DATE_FORMAT_OPTION);
        }

        return options;
      }
    }),
    hasDateFieldInPivotTable: Ember.computed('widgetModel.{displayType,settings.fields.@each.dataType}', {
      get: function get() {
        var fields = this.get('widgetModel.settings.fields');
        var rows = this.get('widgetModel.settings.query.rows');
        var columns = this.get('widgetModel.settings.query.columns'); // consider only row and column fields to determine whether or not to show dateFieldPicker

        var rowFields = rows.map(function (rowName) {
          return fields.find(function (field) {
            return field.get('name') === rowName;
          });
        });
        var columnFields = columns.map(function (columnName) {
          return fields.find(function (field) {
            return field.get('name') === columnName;
          });
        });
        var rowAndColumnFields = [].concat(_toConsumableArray(rowFields), _toConsumableArray(columnFields));
        return this.get('widgetModel.displayType') === 'pivot_table' && (rowAndColumnFields.isAny('dataType', 'date') || rowAndColumnFields.isAny('dataType', 'timestamp'));
      }
    }),
    isXAxisDateTimeType: Ember.computed('widgetModel.settings.fields.@each.name', 'xAxisFieldName', {
      get: function get() {
        var fieldName = this.get('xAxisFieldName');

        if (fieldName) {
          var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);

          if (field) {
            var fieldType = field.get('dataType');

            if (fieldType === 'timestamp') {
              var format = field.get('format');
              return _constants.FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS.includes(format);
            }
          }
        }

        return false;
      }
    }),
    isXAxisDateType: Ember.computed('widgetModel.settings.fields.@each.name', 'xAxisFieldName', {
      get: function get() {
        var fieldName = this.get('xAxisFieldName');

        if (fieldName) {
          var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);

          if (field) {
            var fieldType = field.get('dataType');

            if (fieldType === 'date') {
              var format = field.get('format');
              return _constants.FORMAT_SWITCHER_DATE_BASED_OPTIONS.includes(format);
            }
          }
        }

        return false;
      }
    }),
    selectedDateFormat: Ember.computed('widgetModel.preferences.dateFormat', 'seekedDateRange.{fromDate,toDate}', {
      get: function get() {
        var dateFormatOptions = this.dateFormatOptions,
            visualization = this.widgetModel;
        var hasDateRangeField = visualization.get('settings.query.dateRangeField');
        var selectedDateFormat = hasDateRangeField ? AUTO_DATE_FORMAT_OPTION : dateFormatOptions.findBy('id', 'date');
        var xAxisFieldName = this.get('xAxisFieldName');
        var dateField = null;

        if (xAxisFieldName) {
          dateField = this.get('widgetModel.settings.fields').findBy('name', xAxisFieldName);
        }

        if (this.get('hasDateFieldInPivotTable')) {
          var fields = this.get('widgetModel.settings.fields');
          fields.forEach(function (field) {
            if ((field.get('dataType') === 'date' || field.get('dataType') === 'timestamp') && field.get('format')) {
              dateField = field;
            }
          });
        }

        if (dateField && dateField.get('format')) {
          var fieldDateFormat = dateField.get('format');
          var preferences = visualization.get('preferences');
          var prefDateFormat = preferences && preferences.get('dateFormat');

          if (prefDateFormat && prefDateFormat !== 'auto') {
            selectedDateFormat = dateFormatOptions.findBy('id', prefDateFormat);
          } else if (_constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(fieldDateFormat)) {
            selectedDateFormat = AUTO_DATE_FORMAT_OPTION;
          } else {
            selectedDateFormat = dateFormatOptions.findBy('id', fieldDateFormat);
          }
        }

        return selectedDateFormat;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    filterFields: Ember.computed('widgetModel.settings.fields.[]', 'widgetModel.settings.fields.@each.{isEditable,dataset}', {
      get: function get() {
        return this.get('widgetModel.settings.fields').filterBy('isEditable').rejectBy('type', 'dataset');
      }
    }),
    viewWidgetComponent: Ember.computed('widgetModel.displayType', {
      get: function get() {
        var widgetType = this.get('widgetModel.displayType');

        if (Ember.isPresent(widgetType)) {
          return "components/".concat(widgetType.dasherize());
        }
      }
    }),
    canShowFullScreenOption: Ember.computed('isAnyElementInFullscreen', 'isWidgetTileInFullScreen', {
      get: function get() {
        return !(this.get('isAnyElementInFullscreen') && !this.get('isWidgetTileInFullScreen'));
      }
    }),
    hasEditableFilterFields: Ember.computed('filterFields.@each.isEditable', {
      get: function get() {
        return this.get('filterFields').isAny('isEditable');
      }
    }),
    classNameForFullscreen: Ember.computed('widgetModel.id', {
      get: function get() {
        return "js-widget-tile-".concat(this.get('widgetModel.id'));
      }
    }),
    onDateRangePreferencesChange: Ember.observer('widgetModel.settings.query.dateRangeField', 'dateRangePreferences.{dateRange,fromDate,toDate}', function () {
      var dateRangeField = this.get('widgetModel.settings.query.dateRangeField');
      var existingDateRangeData = this.get('existingDateRangeData');
      var dateRangePreferences = this.get('dateRangePreferences');

      if (dateRangeField) {
        // Observer fires thrice on date range change because of 'dateRangePreferences.{dateRange,fromDate,toDate}'
        // Using 'once' will make sure 'loadData' is called only once
        if (!(dateRangeField === existingDateRangeData.dateRangeField && dateRangePreferences.get('dateRange') === existingDateRangeData.dateRange && dateRangePreferences.get('fromDate') === existingDateRangeData.fromDate && dateRangePreferences.get('toDate') === existingDateRangeData.toDate)) {
          Ember.run.once(this, 'loadData');
          this.set('existingDateRangeData', {
            dateRange: dateRangePreferences.get('dateRange'),
            fromDate: dateRangePreferences.get('fromDate'),
            toDate: dateRangePreferences.get('toDate'),
            dateRangeField: dateRangeField,
            compare: dateRangePreferences.get('compare')
          });
        }
      }
    }),
    onCompareDateRangePreferencesChange: Ember.observer('compareDateRangePreferences.@each.{dateRange,fromDate,toDate}', function () {
      var existingDateRangeData = this.get('existingDateRangeData');
      var compareDateRangePreferences = this.get('compareDateRangePreferences');

      if (!_.isEqual(existingDateRangeData.compare, compareDateRangePreferences)) {
        Ember.run.once(this, 'loadDataByCompareDateRangeHelper');
      }
    }),
    _setupScrollOnFocus: function _setupScrollOnFocus() {
      var _this2 = this;

      this._documentClickHandler = function (event) {
        if (_this2.element.contains(event.target) || event.target === _this2.element) {
          // clicking on the widget tile must not unset the data attribute
          if (_this2.element.getAttribute('data-widget-in-focus') === 'true') {
            return;
          }
        }

        _this2.element.classList.remove('widget-tile_in-focus');

        _this2.element.setAttribute('data-widget-in-focus', 'false');

        document.removeEventListener('click', _this2._documentClickHandler);
      };

      this.element.addEventListener('click', function () {
        var isInFocus = _this2.element.getAttribute('data-widget-in-focus'); // only set the data attribute and event listener, if it is not already set


        if (!isInFocus || isInFocus === 'false') {
          _this2.element.classList.add('widget-tile_in-focus');

          _this2.element.setAttribute('data-widget-in-focus', 'true');

          document.addEventListener('click', _this2._documentClickHandler);
        }
      });
    },
    loadDataByCompareDateRangeHelper: function loadDataByCompareDateRangeHelper() {
      this.loadDataByCompareDateRange();
      var existingDateRangeData = this.get('existingDateRangeData');
      existingDateRangeData.compare = this.get('compareDateRangePreferences');
    },
    onSeekedDateRangeChange: Ember.observer('seekedDateRange.{fromDate,toDate}', function () {
      var dateRange = this.get('seekedDateRange');

      if (dateRange.fromDate && dateRange.toDate) {
        Ember.run.once(this, 'loadDataBySeekedDateRange');
      } else {
        // when user reset seekedDateRange, then we need to load data by profile date pref.
        Ember.run.once(this, 'loadData');
      }
    }),
    // Visualization will be changed only for preview in create/edit visualization
    onVisualizationChange: Ember.observer('widgetModel', function () {
      this.loadData();
    }),
    getDateRangeForRequest: function getDateRangeForRequest() {
      var seekedDateRange = this.get('seekedDateRange');
      var dateRangePreferences = this.get('dateRangePreferences');

      if (seekedDateRange.fromDate || seekedDateRange.toDate) {
        return seekedDateRange;
      } // Date range preferences has info about `compare` as well. It is unnecessary to send compare for `reports-data`


      return Ember.Object.create({
        'dateRange': dateRangePreferences.dateRange,
        'fromDate': dateRangePreferences.fromDate,
        'toDate': dateRangePreferences.toDate
      });
    },
    loadData: function loadData() {
      var _this3 = this;

      var onInit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var widgetId = this.get('widgetModel.id');

      if (this.isTextTile) {
        return;
      }

      if (this.get('canCompareDateRange')) {
        this.loadDataByCompareDateRange();
      } else {
        var requestData = {
          'visualization_id': widgetId
        };
        var isXAxisDateAxis = this.isXAxisDateType || this.isXAxisDateTimeType;

        if (onInit && !this.get('isPreview') && isXAxisDateAxis && !this.get('widgetModel.preferences.dateFormat')) {
          var xAxisFieldName = this.get('xAxisFieldName');

          if (xAxisFieldName) {
            var field = this.get('widgetModel.settings.fields').findBy('name', xAxisFieldName);

            if (field) {
              var format = field.get('format');

              if (_constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(format)) {
                requestData.dateFormat = 'auto';
              } else {
                requestData.dateFormat = format;
              }
            }
          }
        }

        this.getVisualizationDataHelper(requestData).then(function (data) {
          _this3.set('widgetData', data);
        });
      }
    },
    loadDataBySeekedDateRange: function loadDataBySeekedDateRange() {
      var _this4 = this;

      var widgetId = this.get('widgetModel.id');
      var dateRange = this.get('seekedDateRange');

      if (this.get('canCompareDateRange')) {
        this.loadDataByCompareDateRange();
      } else {
        var requestData = _objectSpread({
          'visualization_id': widgetId
        }, dateRange);

        this.getVisualizationDataHelper(requestData).then(function (data) {
          _this4.set('widgetData', data);
        });
      }
    },

    /*  Called upon
        - Init
        - On Date Range Change
        - On Seeked Date Range change
        - On Compare date Range change
        - On Timestamp format change
    */
    loadDataByCompareDateRange: function loadDataByCompareDateRange() {
      var _this5 = this;

      if (this.isTextTile) {
        return;
      }

      this.set('compareDateRangesList', Ember.A());
      var widgetId = this.get('widgetModel.id');
      var dateFormat = this.get('selectedDateFormat');
      var dateRangesHash = Ember.Object.create();
      var actualDateRange = this.getDateRangeForRequest();
      var compareDateRangesList = this.getCompareDateRangesList();
      (compareDateRangesList || []).forEach(function (compareDateRange) {
        var compareRequestData = {
          'visualization_id': widgetId,
          'dateRange': 'custom',
          // Sending dateRange as 'custom' for all compare types (last_period, last_year, etc) for `reports-data` consistency
          'fromDate': compareDateRange.fromDate,
          'toDate': compareDateRange.toDate
        };

        if (dateFormat && dateFormat.id) {
          compareRequestData['date_format'] = dateFormat.id;
        }

        dateRangesHash.set(compareDateRange.dateRange, _this5.getVisualizationData(compareRequestData));
      });
      var actualRequestData = {
        'visualization_id': widgetId
      };

      if (this.get('seekedDateRange.fromDate') || this.get('seekedDateRange.toDate')) {
        actualRequestData = Object.assign(actualRequestData, actualDateRange);
      }

      if (dateFormat && dateFormat.id) {
        actualRequestData['date_format'] = dateFormat.id;
      }

      dateRangesHash.set('actual_date_range', this.getVisualizationData(actualRequestData));
      this.set('isLoadingData', true);
      Ember.RSVP.hash(dateRangesHash).then(function (comparisonData) {
        Object.keys(comparisonData).forEach(function (dateRangeKey) {
          if (dateRangeKey !== 'actual_date_range') {
            var compareField = compareDateRangesList.findBy('dateRange', dateRangeKey);
            compareField.set('data', comparisonData[dateRangeKey]);
          } else {
            _this5.set('widgetData', comparisonData[dateRangeKey]);
          }
        });

        _this5.set('compareDateRangesList', compareDateRangesList);
      }).finally(function () {
        _this5.set('isLoadingData', false);
      });
    },
    getCompareDateRangesList: function getCompareDateRangesList() {
      var compareDateRangesList = Ember.A();
      var actualDateRange = this.getDateRangeForRequest();
      var compareDateRangePreferences = this.get('compareDateRangePreferences');
      var compareDatePredefinedRanges = (0, _utilities.getCompareDateRanges)(actualDateRange);
      (compareDateRangePreferences || []).forEach(function (field) {
        var compareDateRange = Ember.Object.create();
        var compareDatum = compareDatePredefinedRanges.findBy('id', field.dateRange);

        if (compareDatum) {
          compareDateRange.setProperties({
            'dateRange': compareDatum.id,
            'fromDate': (0, _date.convertDateToApiFormat)(compareDatum.value[0]),
            'toDate': (0, _date.convertDateToApiFormat)(compareDatum.value[1])
          });
        } else {
          // 'custom'
          compareDateRange.setProperties(_objectSpread({}, field));
        }

        compareDateRangesList.addObject(compareDateRange);
      });
      return compareDateRangesList;
    },
    getDateInApiFormat: function getDateInApiFormat(dateObject) {
      return "".concat(dateObject.getFullYear(), "-").concat(dateObject.getMonth() + 1, "-").concat(dateObject.getDate());
    },
    getVisualizationDataHelper: function getVisualizationDataHelper(data) {
      var _this6 = this;

      this.set('isLoadingData', true);
      return this.getVisualizationData(data).then(function (response) {
        _this6.set('isRequestFailure', false);

        return response;
      }).catch(function () {
        _this6.set('isRequestFailure', true);
      }).finally(function () {
        _this6.set('isLoadingData', false);
      });
    },
    actions: {
      removeTemporaryVisualization: function removeTemporaryVisualization() {
        this.onRemoveTemporaryVisualization();
      },
      toggleValueLabels: function toggleValueLabels(popOver) {
        var _this7 = this;

        var showValueLabels = this.get('showValueLabels');
        var visualizationId = this.get('widgetModel.id');
        popOver.closePopOver();
        this.set('isLoadingData', true);
        this.get('dataService').changeVisualizationPreferences(visualizationId, {
          'showValueLabels': !showValueLabels
        }).then(function (preferences) {
          _this7.set('showValueLabels', preferences.showValueLabels);
        }).finally(function () {
          _this7.set('isLoadingData', false);
        });
      },
      toggleFullscreen: function toggleFullscreen() {
        var _Ember$$ = Ember.$(".".concat(this.get('classNameForFullscreen'))),
            _Ember$$2 = _slicedToArray(_Ember$$, 1),
            element = _Ember$$2[0];

        this.get('fullscreenService').toggleFullscreen(element);
        this.toggleProperty('isWidgetTileInFullScreen');
      },
      toggleShowRawData: function toggleShowRawData() {
        this.toggleProperty('showRawData');

        if (!this.get('showRawData')) {
          this.set('rawDataFilters', null);
          this.loadData();
        }
      },
      applyFilters: function applyFilters(filters) {
        var _this8 = this;

        var visualizationId = this.get('widgetModel.id');
        var requestData = {
          'visualization_id': visualizationId,
          'filters': {
            'matchAll': filters
          }
        };
        return this.getVisualizationDataHelper(requestData).then(function (data) {
          if (filters) {
            _this8.set('widgetModel.preferences.filters.matchAll', filters);
          }

          _this8.set('widgetData', data);
        });
      },
      edit: function edit() {
        var visualizationId = this.get('widgetModel.id');
        this.triggerEditVisualization(visualizationId);
      },
      exportRawData: function exportRawData(id) {
        this.get('dataService').exportRawData(id);
      },
      openSampleVisualizationModal: function openSampleVisualizationModal() {
        this.set('canShowSampleVisualizationModal', true);
      },
      openCloneVisualizationModal: function openCloneVisualizationModal() {
        this.set('canShowCloneVisualizationModal', true);
      },
      openMoveVisualizationModal: function openMoveVisualizationModal() {
        this.set('canShowMoveVisualizationModal', true);
      },
      cloneAndEditVisualization: function cloneAndEditVisualization(visualizationId) {
        this.set('canShowCloneVisualizationModal', false);
        this.triggerEditVisualization(visualizationId);
      },
      cloneAndViewPage: function cloneAndViewPage(pageId) {
        this.set('canShowCloneVisualizationModal', false);
        this.goToPageDetailAction(pageId);
      },
      closeCloneVisualizationModal: function closeCloneVisualizationModal() {
        this.set('canShowCloneVisualizationModal', false);
      },
      closeSampleVisualizationModal: function closeSampleVisualizationModal() {
        this.set('canShowSampleVisualizationModal', false);
      },
      closeMoveVisualizationModal: function closeMoveVisualizationModal() {
        var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var pageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.set('canShowMoveVisualizationModal', false);

        if (refresh) {
          this.goToPageDetailAction(pageId);
        }
      },
      showRawDataWithFilter: function showRawDataWithFilter(filters) {
        if (!this.get('isPreview')) {
          this.set('rawDataFilters', filters);
          this.toggleProperty('showRawData');
        }
      },
      seekedDateRangeChanged: function seekedDateRangeChanged(dateInfo) {
        var start = this.getDateInApiFormat(dateInfo.startDate);
        var end = this.getDateInApiFormat(dateInfo.endDate);
        this.get('seekedDateRange').setProperties({
          fromDate: start,
          toDate: end
        });
      },
      toggleDeleteVisualization: function toggleDeleteVisualization() {
        this.toggleProperty('canShowDeleteConfirmation');
      },
      deleteVisualization: function deleteVisualization() {
        var _this9 = this;

        this.set('isDeleteInProgress', true);
        this.get('widgetModel').destroyRecord().finally(function () {
          _this9.send('toggleDeleteVisualization');

          _this9.set('isDeleteInProgress', false);

          _this9.refreshPageDetailAction();
        });
      },
      onDateFormatChange: function onDateFormatChange(selectedDateFormat) {
        var _this10 = this;

        this.set('selectedDateFormat', selectedDateFormat);
        var dateRange = this.getDateRangeForRequest();
        var newFormat = selectedDateFormat.id;
        var visualization = this.get('widgetModel');

        if (this.get('canCompareDateRange')) {
          this.loadDataByCompareDateRange();
        } else {
          var requestData = _objectSpread({
            visualizationId: visualization.get('id'),
            'date_format': newFormat
          }, dateRange);

          this.getVisualizationDataHelper(requestData).then(function (data) {
            _this10.set('widgetData', data);

            visualization.set('preferences.dateFormat', newFormat);
          });
        }
      }
    }
  });

  _exports.default = _default;
});