define("happyfox-bi/data-sources/details/data-sets/index/controller", ["exports", "ember-light-table", "happyfox-bi/mixins/table"], function (_exports, _emberLightTable, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_table.default, {
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    isOnDemandSyncEnabled: Ember.computed.reads('featuresService.onDemandSync'),
    dataSource: null,
    canShowSyncFeature: Ember.computed('dataSource', 'isOnDemandSyncEnabled', {
      get: function get() {
        return this.isOnDemandSyncEnabled && this.dataSource.sourceType === 'happyfox';
      }
    }),
    initiate: function initiate(dataSourceId) {
      var dataSource = this.get('dataService').peekDataSource(dataSourceId);
      this.set('dataSource', dataSource);
      var dataSets = this.get('model');
      var columns = [{
        valuePath: 'displayNameOrName',
        label: 'Name',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column'
      }, {
        valuePath: 'lastSyncAt',
        label: 'Last Sync At',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column'
      }];
      this.set('columns', columns);

      var table = _emberLightTable.default.create({
        columns: columns,
        rows: dataSets
      });

      var rows = table.rows;
      this.setProperties({
        rows: rows,
        table: table
      });
    },
    actions: {
      onSyncStatusUpdate: function onSyncStatusUpdate(status) {
        this.set('dataSource.settings.status', status);
        var dataSource = this.get('dataSource');
        this.set('dataSource', dataSource);
      }
    }
  });

  _exports.default = _default;
});