define("happyfox-bi/visualizations/new/components/fields-selection/field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-create-edit-fields-selection_field'],
    fieldTitle: null,
    field: null,
    showEditFieldModal: false,
    canShowGroupingOptions: true,
    canShowBucketValuesOptions: true,
    canShowAggregation: true,
    canShowFilters: false,
    isAggregationTypeMandatory: false,
    disableAggregation: false,
    aggregationDisabledHelpText: null,
    settingsFields: null,
    hideFormulaFields: false,
    displayName: Ember.computed('field.{name,displayName}', {
      get: function get() {
        return this.get('field.displayName') || this.get('field.name');
      }
    }),
    isEditDisabled: Ember.computed('field.{type,isCalculatedField}', {
      get: function get() {
        var fieldType = this.get('field.type');
        return !this.get('field.isCalculatedField') && (fieldType === 'formula_field' || fieldType === 'aggregation_formula');
      }
    }),
    actions: {
      toggleEditFieldModal: function toggleEditFieldModal() {
        this.toggleProperty('showEditFieldModal');
      },
      remove: function remove() {
        var field = this.get('field');
        this.removeField(field);
      },
      edit: function edit(newField) {
        var oldField = this.get('field');
        this.onEdit(newField, oldField);
        this.toggleProperty('showEditFieldModal');
      },
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      }
    }
  });

  _exports.default = _default;
});