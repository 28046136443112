define("happyfox-bi/visualizations/new/components/fields-selection/pivot-table/component", ["exports", "happyfox-bi/visualizations/new/components/fields-selection/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_LIMIT = 20;

  var _default = _component.default.extend({
    intlService: Ember.inject.service('intl'),
    classNames: ['new-create-edit-fields-selection'],
    rows: null,
    columns: null,
    values: null,
    pivotTableIncludePercentageOption: null,
    displaySettings: null,
    settingsFields: null,
    rowFields: null,
    columnFields: null,
    valueFields: null,
    showRowFieldModal: false,
    showColumnFieldModal: false,
    showValueFieldModal: false,
    canAddRows: Ember.computed.lt('rowFields.length', 2),
    canAddColumns: Ember.computed.lt('columnFields.length', 2),
    canAddValues: Ember.computed.lt('valueFields.length', MAX_LIMIT),
    init: function init() {
      this._super.apply(this, arguments);

      var displaySettings = this.displaySettings,
          settingsFields = this.settingsFields;
      var rows = displaySettings.rows,
          columns = displaySettings.columns,
          values = displaySettings.values;
      var rowFields = rows.map(function (field) {
        return settingsFields.findBy('name', field.key);
      }).compact();
      var columnFields = columns.map(function (field) {
        return settingsFields.findBy('name', field.key);
      }).compact();
      var valueFields = values.map(function (field) {
        return settingsFields.findBy('name', field.key);
      }).compact();
      this.setProperties({
        rowFields: rowFields,
        columnFields: columnFields,
        valueFields: valueFields
      });
    },
    hasNonAggregatedValues: Ember.computed('valueFields.[]', {
      get: function get() {
        var valueFields = this.valueFields;
        var isOnlyNonAggregationValues = valueFields.every(function (field) {
          var isOfTypeAggregationForumla = field.type === 'aggregation_formula';
          return !Ember.isPresent(field.aggregationType) && !isOfTypeAggregationForumla;
        });
        return Ember.isPresent(valueFields) && isOnlyNonAggregationValues;
      }
    }),
    isAddFieldAggregationMandatory: Ember.computed('hasNonAggregatedValues', {
      get: function get() {
        var valueFields = this.valueFields,
            hasNonAggregatedValues = this.hasNonAggregatedValues;
        return Ember.isPresent(valueFields) && !hasNonAggregatedValues;
      }
    }),
    isEditFieldAggregationMandatory: Ember.computed('hasNonAggregatedValues', {
      get: function get() {
        var valueFields = this.valueFields,
            hasNonAggregatedValues = this.hasNonAggregatedValues;
        return Ember.isPresent(valueFields) && valueFields.length > 1 && !hasNonAggregatedValues;
      }
    }),
    isAddFieldAggregationDisabled: Ember.computed('hasNonAggregatedValues', {
      get: function get() {
        var hasNonAggregatedValues = this.hasNonAggregatedValues,
            valueFields = this.valueFields;
        return Ember.isPresent(valueFields) && hasNonAggregatedValues;
      }
    }),
    isEditFieldAggregationDisabled: Ember.computed('hasNonAggregatedValues', {
      get: function get() {
        var hasNonAggregatedValues = this.hasNonAggregatedValues,
            valueFields = this.valueFields;
        return Ember.isPresent(valueFields) && valueFields.length > 1 && hasNonAggregatedValues;
      }
    }),
    addFieldAggregationDisabledHelpText: Ember.computed('isAddFieldAggregationDisabled', 'isAddFieldAggregationMandatory', {
      get: function get() {
        var intlService = this.intlService,
            isAddFieldAggregationDisabled = this.isAddFieldAggregationDisabled,
            isAddFieldAggregationMandatory = this.isAddFieldAggregationMandatory;

        if (!isAddFieldAggregationDisabled && isAddFieldAggregationMandatory) {
          return intlService.lookup('disabled-no-aggregation-type-help-text');
        } else if (isAddFieldAggregationDisabled && !isAddFieldAggregationMandatory) {
          return intlService.lookup('disabled-aggregation-type-help-text');
        }
      }
    }),
    editFieldAggregationDisabledHelpText: Ember.computed('isEditFieldAggregationDisabled', 'isEditFieldAggregationMandatory', {
      get: function get() {
        var intlService = this.intlService,
            isEditFieldAggregationDisabled = this.isEditFieldAggregationDisabled,
            isEditFieldAggregationMandatory = this.isEditFieldAggregationMandatory;

        if (!isEditFieldAggregationDisabled && isEditFieldAggregationMandatory) {
          return intlService.lookup('disabled-no-aggregation-type-help-text');
        } else if (isEditFieldAggregationDisabled && !isEditFieldAggregationMandatory) {
          return intlService.lookup('disabled-aggregation-type-help-text');
        }
      }
    }),
    updateVisualizationRowFields: function updateVisualizationRowFields() {
      this.generateDisplaySettings();
      var hasNonAggregatedValues = this.hasNonAggregatedValues,
          rowFields = this.rowFields,
          columnFields = this.columnFields,
          valueFields = this.valueFields;

      if (hasNonAggregatedValues) {
        var fields = Ember.A().addObjects(rowFields).addObjects(columnFields).addObjects(valueFields);
        this.tableAsPivotTableFieldsChange(fields);
      } else {
        this.onRowsChange(rowFields, false);
      }
    },
    updateVisualizationColumnFields: function updateVisualizationColumnFields() {
      this.generateDisplaySettings();
      var hasNonAggregatedValues = this.hasNonAggregatedValues,
          rowFields = this.rowFields,
          columnFields = this.columnFields,
          valueFields = this.valueFields;

      if (hasNonAggregatedValues) {
        var fields = Ember.A().addObjects(rowFields).addObjects(columnFields).addObjects(valueFields);
        this.tableAsPivotTableFieldsChange(fields);
      } else {
        this.onColumnsChange(columnFields, false);
      }
    },
    updateVisualizationValueFields: function updateVisualizationValueFields() {
      this.generateDisplaySettings();
      var hasNonAggregatedValues = this.hasNonAggregatedValues,
          rowFields = this.rowFields,
          columnFields = this.columnFields,
          valueFields = this.valueFields;

      if (hasNonAggregatedValues) {
        var fields = Ember.A().addObjects(rowFields).addObjects(columnFields).addObjects(valueFields);
        this.tableAsPivotTableFieldsChange(fields);
      } else {
        this.setProperties({
          rows: rowFields,
          columns: columnFields
        });
        this.onValuesChange(valueFields, false);
      }
    },
    tableAsPivotTableFieldsChange: function tableAsPivotTableFieldsChange(columnFields) {
      var displaySettings = this.displaySettings;
      this.setProperties({
        rows: Ember.A(),
        values: Ember.A(),
        pivotTableIncludePercentageOption: null // Reset percentage option (if existed)

      });
      displaySettings.setProperties({
        showTotals: false,
        showAverages: false,
        showPercentages: false
      });
      this.onColumnsChange(columnFields, false);
    },
    generateDisplaySettings: function generateDisplaySettings() {
      var rowFields = this.rowFields,
          columnFields = this.columnFields,
          valueFields = this.valueFields,
          displaySettings = this.displaySettings;
      displaySettings.setProperties({
        rows: this.getPivotTableSettings('rows', rowFields),
        columns: this.getPivotTableSettings('columns', columnFields),
        values: this.getPivotTableSettings('values', valueFields),
        tableAsPivotTable: this.hasNonAggregatedValues
      });
    },
    getPivotTableSettings: function getPivotTableSettings(displaySettingsKey, fields) {
      if (Ember.isPresent(fields)) {
        var tableFieldSettings = this.displaySettings.get(displaySettingsKey);
        var fieldSettings = Ember.A();

        if (Ember.isPresent(tableFieldSettings)) {
          fields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: '',
              label: ''
            });
            var existingField = tableFieldSettings.findBy('key', field.name);

            if (existingField) {
              setting.setProperties({
                key: existingField.key,
                label: existingField.label
              });
            } else {
              setting.setProperties({
                key: field.name,
                label: field.displayName || field.name
              });
            }

            fieldSettings.pushObject(setting);
          });
        } else {
          fields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: field.name,
              label: field.displayName || field.name
            });
            fieldSettings.pushObject(setting);
          });
        }

        return fieldSettings;
      }

      return Ember.A();
    },
    actions: {
      removeRowField: function removeRowField(field) {
        var rowFields = this.rowFields;
        rowFields.removeObject(field);
        this.updateVisualizationRowFields();
      },
      removeColumnField: function removeColumnField(field) {
        var columnFields = this.columnFields;
        columnFields.removeObject(field);
        this.updateVisualizationColumnFields();
      },
      removeValueField: function removeValueField(field) {
        var valueFields = this.valueFields;
        valueFields.removeObject(field);
        this.updateVisualizationValueFields();
      },
      addRowField: function addRowField(field) {
        var rowFields = this.rowFields;
        rowFields.addObject(field);
        this.toggleProperty('showRowFieldModal');
        this.updateVisualizationRowFields();
      },
      addColumnField: function addColumnField(field) {
        var columnFields = this.columnFields;
        columnFields.addObject(field);
        this.toggleProperty('showColumnFieldModal');
        this.updateVisualizationColumnFields();
      },
      addValueField: function addValueField(field) {
        var valueFields = this.valueFields;
        valueFields.addObject(field);
        this.toggleProperty('showValueFieldModal');
        this.updateVisualizationValueFields();
      },
      toggleRowFieldModal: function toggleRowFieldModal() {
        this.toggleProperty('showRowFieldModal');
      },
      toggleColumnFieldModal: function toggleColumnFieldModal() {
        this.toggleProperty('showColumnFieldModal');
      },
      toggleValueFieldModal: function toggleValueFieldModal() {
        this.toggleProperty('showValueFieldModal');
      },
      editRowField: function editRowField(newField, field) {
        var rowFields = this.rowFields;
        var indexOfOldField = rowFields.indexOf(field);
        rowFields.removeObject(field);
        rowFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowFields();
      },
      editColumnField: function editColumnField(newField, field) {
        var columnFields = this.columnFields;
        var indexOfOldField = columnFields.indexOf(field);
        columnFields.removeObject(field);
        columnFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationColumnFields();
      },
      editValueField: function editValueField(newField, field) {
        var valueFields = this.valueFields;
        var indexOfOldField = valueFields.indexOf(field);
        valueFields.removeObject(field);
        valueFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationValueFields();
      }
    }
  });

  _exports.default = _default;
});