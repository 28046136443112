define("happyfox-bi/application/top-bar/theme-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BASE_THEMES = [{
    app: 'light',
    name: 'theme-light',
    charts: 'light'
  }, {
    app: 'dark',
    name: 'theme-dark',
    charts: 'dark'
  }];
  var ADDITIONAL_THEMES = [{
    app: 'material',
    name: 'theme-material',
    charts: 'material'
  }, {
    app: 'amcharts',
    name: 'theme-amcharts',
    charts: 'amcharts'
  }, {
    app: 'frozen',
    name: 'theme-frozen',
    charts: 'frozen'
  }, {
    app: 'kelly',
    name: 'theme-kelly',
    charts: 'kelly'
  }, {
    app: 'microchart',
    name: 'theme-microchart',
    charts: 'microchart'
  }, {
    app: 'moonrisekingdom',
    name: 'theme-moonrisekingdom',
    charts: 'moonrisekingdom'
  }, {
    app: 'patterns',
    name: 'theme-patterns',
    charts: 'patterns'
  }, {
    app: 'spiritedaway',
    name: 'theme-spiritedaway',
    charts: 'spiritedaway'
  }, {
    app: 'dataviz',
    name: 'theme-dataviz',
    charts: 'dataviz'
  }];
  var DEFAULT_THEME = {
    app: 'dark',
    name: 'theme-dark',
    charts: 'dark'
  };

  var _default = Ember.Component.extend({
    featuresService: Ember.inject.service('features'),
    classNames: ['theme-selection'],
    isOpen: false,
    theme: null,
    themeOptions: Ember.computed('featuresService.experimentalChartThemes', function () {
      return this.get('featuresService.experimentalChartThemes') ? [].concat(BASE_THEMES, ADDITIONAL_THEMES) : BASE_THEMES;
    }),
    currentTheme: Ember.computed('theme.{app,charts}', {
      get: function get() {
        var theme = Ember.isPresent(this.get('theme.app')) ? this.get('theme') : DEFAULT_THEME;
        return this.get('themeOptions').findBy('app', theme.app);
      }
    }),
    actions: {
      selectTheme: function selectTheme(selectedTheme) {
        if (!_.isEqual(this.get('currentTheme'), selectedTheme)) {
          this.onChangeTheme(selectedTheme);
        }
      }
    }
  });

  _exports.default = _default;
});