define("happyfox-bi/components/invite-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INVITE_OPTIONS = [{
    id: 'client',
    name: 'invite-client'
  }];

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    toastMessageService: Ember.inject.service('toast-message'),
    selectedRole: 'user',
    isInviteUser: Ember.computed.equal('selectedRole', 'user'),
    inviteOptions: INVITE_OPTIONS,
    errors: null,
    isInviteButtonDisabled: Ember.computed('email.length', {
      get: function get() {
        var re = /\S+@\S+\.\S+/; // simple regex to test email format. blahblah@blah.blahblah

        var email = this.get('email');
        return !re.test(email);
      }
    }),
    actions: {
      toggleInviteModal: function toggleInviteModal() {
        this.toggleProperty('canShowInviteModal');
      },
      openInviteModal: function openInviteModal() {
        var role = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'user';
        this.setProperties({
          canShowInviteModal: true,
          email: '',
          selectedRole: role,
          errors: null
        });
      },
      onEmailChange: function onEmailChange() {
        this.set('errors', null);
      },
      handleInviteActions: function handleInviteActions(action) {
        this.send('openInviteModal', action.id);
      },
      inviteUser: function inviteUser() {
        var _this = this;

        var role = this.selectedRole,
            email = this.email;
        this.set('inviteInProgress', true);
        this.get('dataService').inviteUser({
          email: email,
          role: role
        }).then(function () {
          _this.send('toggleInviteModal');

          _this.toastMessageService.showMessage({
            type: 'success',
            text: _this.isInviteUser ? 'invite-user-success-message' : 'invite-client-success-message'
          });
        }).catch(function (_ref) {
          var errors = _ref.errors;

          if (!errors) {
            return;
          }

          if (errors.email) {
            _this.set('errors', errors.email);
          }

          if (errors.nonFieldErrors) {
            _this.set('errors', errors.nonFieldErrors);
          }
        }).finally(function () {
          _this.set('inviteInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});