define("happyfox-bi/visualizations/new/create-edit-controller", ["exports", "happyfox-bi/visualizations/constant", "happyfox-bi/visualizations/new/mixins/visualization-display-settings", "happyfox-bi/mixins/date-range-picker", "happyfox-bi/visualizations/new/utilities/field-name"], function (_exports, _constant, _visualizationDisplaySettings, _dateRangePicker, _fieldName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var UNSELECT_FIELD = {
    timestampFieldName: '-------'
  };
  var DATE_RANGE_FIELD_NAME = 'Date Range Field';
  var VISUALLIZATIONS_WITHOUT_PIVOT_TABLE_VIEW = ['data_tile', 'pivot_table', 'data_table', 'gauge_chart', 'gantt_chart'];

  var _default = Ember.Controller.extend(_dateRangePicker.default, _visualizationDisplaySettings.default, {
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    accountInfoService: Ember.inject.service('account-info'),
    toastMessageService: Ember.inject.service('toast-message'),
    currentTabValue: 'primary',
    currentVisualizationDataView: 'pivot-table',
    isPrimarySettings: Ember.computed.equal('currentTabValue', 'primary'),
    isAdvancedSettings: Ember.computed.equal('currentTabValue', 'advanced'),
    isViewPivotTable: Ember.computed.equal('currentVisualizationDataView', 'pivot-table'),
    isViewRawData: Ember.computed.equal('currentVisualizationDataView', 'raw-data'),
    canViewRawData: Ember.computed.reads('featuresService.viewRawData'),
    visualizationDataViewModel: null,
    previewModel: null,
    previewData: null,
    query: Ember.computed.reads('model.settings.query'),
    visualizationDisplaySettings: Ember.computed.reads('model.displaySettings'),
    visualizationTypeOptions: _constant.VISUALIZATION_OPTIONS,
    model: null,
    isCreate: false,
    dataSets: null,
    dataSetFields: null,
    relatedDataSetFieldsMap: null,
    reportId: null,
    pageId: null,
    reportLevelFields: null,
    pageLevelFields: null,
    includePercentagesOptions: _constant.INCLUDE_PERCENTAGES_OPTIONS,
    pivotTableIncludePercentageOption: null,
    prefetchedFiltersValueOptions: null,
    isLoadingDependencies: false,
    visualizationSettingsMessages: null,
    showAddFilterField: false,
    showAddDrillDownField: false,
    isPreviewDataLoading: false,
    isPreviewDataRequestFailure: false,
    showMergeDataSetsModal: false,
    dataSetsOptions: Ember.computed('dataSets.@each.id', 'visualizationData.joinsList.@each.{primaryDataSet,secondaryDataSet}', {
      get: function get() {
        var dataSets = this.get('dataSets');
        var dataSetsOptions = Ember.A();

        if (dataSets) {
          dataSetsOptions.addObjects(dataSets);
        }

        var joinsList = this.get('visualizationData.joinsList');

        if (Ember.isPresent(joinsList)) {
          var mergeDataSetId = joinsList.get('firstObject.secondaryDataSet');
          var mergeDataSet = this.get('dataSets').findBy('id', mergeDataSetId.toString());
          var dataSetId = joinsList.get('firstObject.primaryDataSet');
          var dataSet = this.get('dataSets').findBy('id', dataSetId.toString());
          var mergeOption = {
            id: 'merge-option',
            isMergedOption: true,
            nameWithDataSource: "'".concat(dataSet.nameWithDataSource, "' combined with '").concat(mergeDataSet.nameWithDataSource, "'")
          };
          dataSetsOptions.addObject(mergeOption);
        }

        var combineOption = {
          id: 'combine-datasets',
          isCombineTrigger: true,
          nameWithDataSource: 'Combine multiple datasets'
        };
        dataSetsOptions.addObject(combineOption);
        return dataSetsOptions;
      }
    }),
    selectedDataSetOption: Ember.computed('visualizationData.{mergeDataSet,dataSet}', 'dataSetsOptions.@each.id', {
      get: function get() {
        var mergeDataSet = this.get('visualizationData.mergeDataSet');
        var dataSet = this.get('visualizationData.dataSet');

        if (mergeDataSet) {
          return this.get('dataSetsOptions').findBy('isMergedOption');
        } else {
          return dataSet;
        }
      }
    }),
    canManageBenchmarks: Ember.computed('featuresService.benchmarks', 'visualizationData.displayType', {
      get: function get() {
        var displayType = this.get('visualizationData').get('displayType.id');
        return this.get('featuresService.benchmarks') && _constant.WIDGETS_WITH_AXIS.includes(displayType);
      }
    }),
    canSelectDrillDownFields: Ember.computed('visualizationData.{displayType,rows.[]}', {
      get: function get() {
        var visualizationData = this.visualizationData;
        var displayType = visualizationData.displayType,
            rows = visualizationData.rows;
        var chartsSupportingDrillDown = ['doughnut_chart', 'bar_chart', 'word_cloud'];

        if (chartsSupportingDrillDown.includes(displayType.id)) {
          // Multiple values without categorical field is also supported by doughnut chart
          // Drilldown for charts with non categorical(rows) fields is not supported yet
          if (displayType.id == 'doughnut_chart') {
            return Ember.isPresent(rows);
          }

          return true;
        }

        return;
      }
    }),
    isDataTile: Ember.computed('visualizationData.displayType', {
      get: function get() {
        var displayType = this.get('visualizationData').get('displayType.id');
        return displayType == 'data_tile';
      }
    }),
    isTextTile: Ember.computed.equal('visualizationData.displayType.id', 'text_tile'),
    isGanttChart: Ember.computed.equal('visualizationData.displayType.id', 'gantt_chart'),
    isPivotTable: Ember.computed.equal('visualizationData.displayType.id', 'pivot_table'),
    isGaugeChart: Ember.computed.equal('visualizationData.displayType.id', 'gauge_chart'),
    isDoughnutChart: Ember.computed.equal('visualizationData.displayType.id', 'doughnut_chart'),
    isDataTable: Ember.computed.equal('visualizationData.displayType.id', 'data_table'),
    isMapChart: Ember.computed('visualizationData.displayType', {
      get: function get() {
        var displayType = this.get('visualizationData').get('displayType.id');
        return displayType == 'map_chart' || displayType == 'map_chart_usa';
      }
    }),
    // Computing on rows and values, as pivot table needs at least one row and one value
    isPivotTableDataViewHidden: Ember.computed('visualizationData.displayType', 'canHidePivotTableForDoughnutChart', {
      get: function get() {
        var visualizationData = this.visualizationData,
            canHidePivotTableForDoughnutChart = this.canHidePivotTableForDoughnutChart;
        var displayType = visualizationData.displayType;
        return VISUALLIZATIONS_WITHOUT_PIVOT_TABLE_VIEW.includes(displayType.id) || canHidePivotTableForDoughnutChart;
      }
    }),
    canHidePivotTableForDoughnutChart: Ember.computed('visualizationData.rows.[]', 'isDoughnutChart', {
      get: function get() {
        var visualizationData = this.visualizationData,
            isDoughnutChart = this.isDoughnutChart;
        var rows = visualizationData.rows;
        return isDoughnutChart && !Ember.isPresent(rows);
      }
    }),
    showVisualizationDataView: Ember.computed('canViewRawData', 'isPivotTableDataViewHidden', 'visualizationSettingsMessages.length', 'isTextTile', {
      get: function get() {
        return !this.get('visualizationSettingsMessages.length') && (this.get('canViewRawData') || !this.get('isPivotTableDataViewHidden')) && !this.isTextTile;
      }
    }),
    timestampDataSetFields: Ember.computed('dataSetFields.@each.dataType', {
      get: function get() {
        var timestampFields = Ember.A();
        var currentDatasetTimestampFields = this.get('dataSetFields').filterBy('dataType', 'timestamp').map(function (field) {
          var displayNameOrName = field.displayNameOrName,
              group = field.group,
              name = field.name,
              id = field.id,
              dataset = field.dataset,
              dataType = field.dataType;
          return Ember.Object.create({
            displayNameOrName: displayNameOrName,
            group: group,
            name: name,
            id: id,
            dataset: dataset,
            dataType: dataType,
            'timestampFieldName': displayNameOrName
          });
        });
        var datasetTypeFields = this.get('dataSetFields').filterBy('dataType', 'dataset');
        var relatedDataSetFieldsMap = this.get('relatedDataSetFieldsMap');
        var allRelatedDataSetIds = [];

        if (relatedDataSetFieldsMap) {
          allRelatedDataSetIds = Object.keys(relatedDataSetFieldsMap).map(function (datasetKey) {
            return Number(datasetKey);
          });
        }

        var relatedDatasetTimestampFields = Ember.A();

        if (datasetTypeFields.length) {
          datasetTypeFields.forEach(function (datasetTypeField) {
            var relatedDataset = datasetTypeField.relatedDataset;

            if (allRelatedDataSetIds.includes(relatedDataset)) {
              var datasetTypeTimestampFields = relatedDataSetFieldsMap[relatedDataset].filterBy('dataType', 'timestamp');
              var datasetTypeTimestampFieldsCopy = datasetTypeTimestampFields.map(function (timestampField) {
                var displayNameOrName = timestampField.displayNameOrName,
                    group = timestampField.group,
                    name = timestampField.name,
                    id = timestampField.id,
                    dataset = timestampField.dataset,
                    dataType = timestampField.dataType;
                return Ember.Object.create({
                  displayNameOrName: displayNameOrName,
                  group: group,
                  name: name,
                  id: id,
                  dataset: dataset,
                  dataType: dataType,
                  'parentDataset': datasetTypeField.get('dataset'),
                  'parentDatasetField': datasetTypeField.get('id'),
                  'timestampFieldName': "".concat(datasetTypeField.displayNameOrName, " - ").concat(timestampField.displayNameOrName)
                });
              });
              relatedDatasetTimestampFields.addObjects(datasetTypeTimestampFieldsCopy);
            }
          });
        }

        if (currentDatasetTimestampFields.length) {
          timestampFields.addObjects(currentDatasetTimestampFields);
        }

        if (relatedDatasetTimestampFields.length) {
          timestampFields.addObjects(relatedDatasetTimestampFields);
        }

        if (timestampFields.length) {
          timestampFields.unshiftObject(UNSELECT_FIELD);
        }

        return timestampFields;
      }
    }),
    reportFieldsForOptIn: Ember.computed('reportLevelFields.@each.dataset', 'visualizationData.dataSet.id', {
      get: function get() {
        var dataSetId = this.get('visualizationData.dataSet.id');
        var reportLevelFields = this.reportLevelFields || [];
        var datasetFieldsForOptIn = reportLevelFields.filterBy('dataset', Number(dataSetId)).rejectBy('parentDataset');
        var relatedDatasetFieldsForOptIn = reportLevelFields.filterBy('parentDataset', Number(dataSetId));
        return datasetFieldsForOptIn.addObjects(relatedDatasetFieldsForOptIn);
      }
    }),
    pageFieldsForOptIn: Ember.computed('pageLevelFields.@each.dataset', 'visualizationData.dataSet.id', {
      get: function get() {
        var dataSetId = this.get('visualizationData.dataSet.id');
        var pageLevelFields = this.pageLevelFields || [];
        var datasetFieldsForOptIn = pageLevelFields.filterBy('dataset', Number(dataSetId)).rejectBy('parentDataset');
        var relatedDatasetFieldsForOptIn = pageLevelFields.filterBy('parentDataset', Number(dataSetId));
        return datasetFieldsForOptIn.addObjects(relatedDatasetFieldsForOptIn);
      }
    }),
    optedInReportFields: Ember.computed('reportFieldsForOptIn.[]', 'visualizationData.optedOutReportFields.[]', {
      get: function get() {
        var reportFieldsForOptIn = this.reportFieldsForOptIn;
        var optedOutReportFields = this.get('visualizationData.optedOutReportFields') || [];
        return reportFieldsForOptIn.slice().removeObjects(optedOutReportFields);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    optedInPageFields: Ember.computed('pageFieldsForOptIn.[]', 'visualizationData.optedOutPageFields.[]', {
      get: function get() {
        var pageFieldsForOptIn = this.pageFieldsForOptIn;
        var optedOutPageFields = this.get('visualizationData.optedOutPageFields') || [];
        return pageFieldsForOptIn.slice().removeObjects(optedOutPageFields);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    disableCreateVisualization: Ember.computed.reads('accountInfoService.isVisualizationsLimitReached'),
    initiate: function initiate() {
      var _this = this;

      var dataService = this.dataService;
      this.setProperties({
        datasetFields: Ember.A(),
        previewModel: Ember.Object.create(),
        prefetchedFiltersValueOptions: Ember.Object.create()
      });

      if (!this.get('isCreate')) {
        var pageId = this.get('model.page');
        var reports = dataService.peekAllReports();
        var report = reports.find(function (report) {
          return report.get('pages').mapBy('id').includes(pageId);
        });
        var reportId = report.get('id'); // Report model doesnt have latest details of page (like filters). Hence using `peekPage`

        var page = dataService.peekPage(pageId);

        if (!page) {
          page = report.get('pages').findBy('id', pageId);
        }

        this.setProperties({
          pageId: pageId,
          reportId: reportId,
          reportLevelFields: report.get('settings.fields') || Ember.A(),
          pageLevelFields: page.get('settings.fields') || Ember.A()
        });
      } else {
        var _pageId = this.get('pageId');

        var _reportId = this.get('reportId');

        var _report = dataService.peekReport(_reportId); // Report model doesnt have latest details of page(like filters). Hence using `peekPage`


        var _page = dataService.peekPage(_pageId);

        if (!_page) {
          _page = _report.get('pages').findBy('id', _pageId);
        }

        this.setProperties({
          reportLevelFields: _report.get('settings.fields') || Ember.A(),
          pageLevelFields: _page.get('settings.fields') || Ember.A()
        });
      }

      var dataSourceId = this.get('query.dataSource');
      var dataSetId = this.get('query.dataSet');

      if (this.get('isCreate')) {
        var primaryDataSet = this.get('dataSets.firstObject');
        dataSetId = primaryDataSet.get('id');
        dataSourceId = primaryDataSet.get('dataSource');
      }

      this.set('isLoadingDependencies', true);
      this.loadDependencies(dataSourceId, dataSetId).then(function () {
        _this.createVisualizationData();

        _this.initializeVisualizationDataView();

        if (_this.validateVisualizationSettings()) {
          _this.triggerPreviewReload();
        }
      }).finally(function () {
        _this.set('isLoadingDependencies', false);
      });
    },
    validateVisualizationSettings: function validateVisualizationSettings() {
      this.set('visualizationSettingsMessages', Ember.A());
      var visualizationData = this.visualizationData,
          visualizationSettingsMessages = this.visualizationSettingsMessages;
      var visualizationType = visualizationData.get('displayType');
      var rowsCount = visualizationData.get('rows.length');
      var columnsCount = visualizationData.get('columns.length');
      var valuesCount = visualizationData.get('values.length');
      var displaySettings = visualizationData.displaySettings;

      if (Ember.isEmpty(visualizationData.get('name'))) {// FIXME
        // this.get('visualizationSettingsMessages').addObject('Add name for visualization');
      }

      if (visualizationType) {
        switch (visualizationType.id) {
          case 'data_tile':
            visualizationData.set('columns', Ember.A());

            if (!(rowsCount === 0 || rowsCount === 1) || valuesCount !== 1) {
              visualizationSettingsMessages.addObjects(['Select 0 or 1 field for label', 'Select 1 field for value']);
            }

            break;

          case 'text_tile':
            visualizationData.setProperties({
              columns: Ember.A(),
              rows: Ember.A(),
              values: Ember.A()
            });

            if (!displaySettings.text) {
              visualizationSettingsMessages.addObjects(['Enter text']);
            }

            break;

          case 'bar_chart':
          case 'line_chart':
          case 'area_chart':
          case 'pareto_chart':
            visualizationData.set('columns', Ember.A());

            if (rowsCount !== 1 || valuesCount !== 1) {
              visualizationSettingsMessages.addObjects(['Select 1 field for X-Axis', 'Select 1 field for Y-Axis']);
            }

            break;

          case 'doughnut_chart':
            visualizationData.set('columns', Ember.A());

            if (rowsCount !== 1 && valuesCount < 2 || valuesCount < 1) {
              this.get('visualizationSettingsMessages').addObjects(['"Select 1 field for field and 1 field for Value" or "0 fields for field and atleast 2 fields for Value"']);
            }

            break;

          case 'funnel_chart':
          case 'word_cloud':
            visualizationData.set('columns', Ember.A());

            if (rowsCount !== 1 || valuesCount !== 1) {
              visualizationSettingsMessages.addObjects(['Select 1 field for field', 'Select 1 field for value']);
            }

            break;

          case 'map_chart':
          case 'map_chart_usa':
            visualizationData.set('columns', Ember.A());

            if (rowsCount !== 1 || valuesCount !== 1) {
              visualizationSettingsMessages.addObjects(['Select 1 field for region', 'Select 1 field for value']);
            }

            break;

          case 'data_table':
            visualizationData.setProperties({
              rows: Ember.A(),
              values: Ember.A()
            });

            if (columnsCount < 2) {
              visualizationSettingsMessages.addObjects(['Select atleast 2 fields for columns']);
            }

            break;

          case 'pivot_table':
            {
              var tableRows = displaySettings.rows,
                  tableColumns = displaySettings.columns,
                  tableValues = displaySettings.values,
                  tableAsPivotTable = displaySettings.tableAsPivotTable;
              var errorMessages = ['Select at least 1 field for rows and maximum of 2', 'Select at least 1 field for columns and maximum of 2', 'Select atleast 1 field for values'];

              if (tableAsPivotTable && (tableRows.length === 0 || tableRows.length > 2 || tableColumns.length === 0 && tableColumns.length > 2 || tableValues.length === 0)) {
                visualizationSettingsMessages.addObjects(errorMessages);
              } else if (!tableAsPivotTable && (rowsCount === 0 || rowsCount > 2 || columnsCount === 0 && columnsCount > 2 || valuesCount === 0)) {
                visualizationSettingsMessages.addObjects(errorMessages);
              }
            }
            break;

          case 'grouped_bar_chart':
          case 'stacked_bar_chart':
          case 'stacked_area_chart':
          case 'multi_area_chart':
          case 'multi_line_chart':
          case 'hundred_percent_stacked_bar_chart':
            if (rowsCount !== 1 || columnsCount !== 0 && valuesCount == 0 || columnsCount == 0 && valuesCount < 2) {
              visualizationSettingsMessages.addObjects(['Select 1 field for X-Axis', '"Select 1 field for Y-Axis and 1 field for Group By" or "0 fields for Group By and atleast 2 fields for Y-Axis"']);
            }

            break;

          case 'life_cycle_chart':
            if (rowsCount !== 1 || columnsCount !== 1 || valuesCount !== 1) {
              visualizationSettingsMessages.addObjects(['Select 1 field for from', 'Select 1 field for to', 'Select 1 field for value']);
            }

            break;

          case 'multi_axis_chart':
            visualizationData.set('columns', Ember.A());

            if (rowsCount !== 1 || valuesCount < 2) {
              visualizationSettingsMessages.addObjects(['Select 1 field for X-Axis', 'Select 1 field for Y-Axis left and 1 field for Y-Axis right']);
            }

            break;

          case 'segmentation_chart':
            visualizationData.set('columns', Ember.A());

            if (rowsCount !== 1 || valuesCount < 2) {
              visualizationSettingsMessages.addObjects(['Select 1 field for Field', 'Select 1 field for X-Axis and 1 field for Y-Axis']);
            }

            break;

          case 'gantt_chart':
            visualizationData.set('columns', Ember.A());
            visualizationData.set('values', Ember.A());

            if (rowsCount < 4) {
              visualizationSettingsMessages.addObjects(['Select 1 field for Category', 'Select 1 field for Task', 'Select 1 field for Start and 1 field for End']);
            }

            break;

          case 'heat_map':
            if (rowsCount !== 1 || columnsCount !== 1 || valuesCount !== 1) {
              visualizationSettingsMessages.addObjects(['Select 1 field for X-Axis', 'Select 1 field for Y-Axis', 'Select 1 field for Value']);
            }

            break;

          case 'tree_map':
            visualizationData.set('columns', Ember.A());

            if (rowsCount == 0 || rowsCount > 3 || valuesCount == 0 || valuesCount > 1) {
              visualizationSettingsMessages.addObjects(['Select at least 1 field for Groups and maximum of 3', 'Select 1 field for Value']);
            }

            break;

          case 'gauge_chart':
            visualizationData.setProperties({
              'columns': Ember.A(),
              'rows': Ember.A()
            });

            if (valuesCount == 0 || valuesCount > 1) {
              visualizationSettingsMessages.addObjects(['Select 1 Target field']);
            }

            break;
        }
      } else {
        visualizationSettingsMessages.addObject('Select a visualization');
      }

      return visualizationSettingsMessages.length === 0;
    },
    // Decides whether pivot-table (or) raw-data view is shown
    initializeVisualizationDataView: function initializeVisualizationDataView() {
      if (this.get('isPivotTableDataViewHidden')) {
        this.setProperties({
          'currentVisualizationDataView': 'raw-data',
          'previewData': null // previewData not needed incase of raw-data view

        });
      } else {
        this.set('currentVisualizationDataView', 'pivot-table');
      }
    },
    setVisualizationDataViewModel: function setVisualizationDataViewModel(view) {
      var displayType;
      var displaySettings = Ember.Object.create();
      var visualizationData = this.get('visualizationData');
      var rowFields = visualizationData.get('rows');
      var columnFields = visualizationData.get('columns');
      var valueFields = visualizationData.get('values');
      displaySettings.set('size', 'square-l');

      if (view == 'pivot-table') {
        displayType = 'pivot-table';
        displaySettings.setProperties({
          rows: rowFields.map(function (field) {
            return Ember.Object.create({
              key: field.get('name'),
              label: field.get('displayName') || field.get('name')
            });
          }),
          columns: columnFields.map(function (field) {
            return Ember.Object.create({
              key: field.get('name'),
              label: field.get('displayName') || field.get('name')
            });
          }),
          values: valueFields.map(function (field) {
            return Ember.Object.create({
              key: field.get('name'),
              label: field.get('displayName') || field.get('name')
            });
          }),
          showTotals: false,
          showAverages: false,
          showPercentages: false
        });
        var visualizationDataViewModel = Ember.Object.create({
          displayType: displayType,
          displaySettings: displaySettings,
          settings: this.get('previewModel.settings')
        });
        this.set('visualizationDataViewModel', visualizationDataViewModel);
      }
    },
    loadDependencies: function loadDependencies(dataSourceId, dataSetId) {
      var _this2 = this;

      this.setProperties({
        dataSetFields: Ember.A(),
        relatedDataSetFieldsMap: null
      });

      if (this.visualizationData && this.isTextTile || !this.visualizationData && this.get('model.displayType') === 'text_tile') {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve();
        });
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('dataService').getDataSetFields(dataSourceId, dataSetId, true).then(function (dataSetFields) {
          _this2.set('dataSetFields', dataSetFields);

          var datasetTypeFields = dataSetFields.filterBy('dataType', 'dataset');

          if (datasetTypeFields.length) {
            var relatedDataSetFieldsMap = Ember.Object.create();
            var relatedDataSetIds = datasetTypeFields.mapBy('relatedDataset').uniq();
            var promisesHash = Ember.Object.create();
            relatedDataSetIds.forEach(function (relatedDataSetId) {
              promisesHash.set(relatedDataSetId.toString(), _this2.get('dataService').getDataSetFields(dataSourceId, relatedDataSetId, true));
            });
            Ember.RSVP.hash(promisesHash).then(function (data) {
              Object.keys(data).forEach(function (id) {
                // TODO: remove this rejectBy once we support multi-level related fields.
                relatedDataSetFieldsMap.set(id, data[id].rejectBy('dataType', 'dataset'));
              });

              _this2.set('relatedDataSetFieldsMap', relatedDataSetFieldsMap);

              resolve();
            }).catch(function () {
              reject();
            });
          } else {
            resolve();
          }
        }).catch(function () {
          reject();
        });
      });
    },
    triggerPreviewReload: function triggerPreviewReload() {
      var visualizationData = this.get('visualizationData');
      var previewModel = Ember.Object.create();
      previewModel.setProperties({
        name: visualizationData.get('name'),
        displayType: visualizationData.get('displayType.id'),
        displaySettings: this.get('visualizationData.displaySettings'),
        settings: this.getSettingsData(true)
      });
      this.set('previewModel', previewModel);
    },
    createVisualizationData: function createVisualizationData() {
      var isCreate = this.get('isCreate');
      var model = this.get('model');
      var query = this.get('query');
      var dataSourceId = query.get('dataSource');
      var dataSetId = query.get('dataSet');
      var settingsFields = Ember.A();
      var settingsFieldsFilters = Ember.Object.create();

      if (isCreate) {
        var defaultDataSet = this.get('dataSets.firstObject');
        dataSetId = defaultDataSet.get('id');
        dataSourceId = defaultDataSet.get('dataSource');
      } else {
        settingsFields = model.get('settings.fields').map(function (field) {
          var settingsField = Ember.Object.create(field);

          if (Ember.isEmpty(settingsField.get('displayName'))) {
            settingsField.set('displayName', settingsField.get('name'));
          }

          return settingsField;
        });
        settingsFields.forEach(function (field) {
          if (field.get('filters')) {
            settingsFieldsFilters.set(field.get('name'), field.get('filters.matchAll'));
          }
        });
      }

      var dataSet = dataSetId && this.get('dataSets').findBy('id', dataSetId.toString());
      var rows = query.get('rows').map(function (field) {
        return settingsFields.findBy('name', field);
      });
      var columns = query.get('columns').map(function (field) {
        return settingsFields.findBy('name', field);
      });
      var values = query.get('values').map(function (field) {
        return settingsFields.findBy('name', field);
      });
      var drillDownFields = (query.get('drillDownFields') || Ember.A()).map(function (field) {
        return settingsFields.findBy('name', field);
      });
      rows.setEach('isUsedForRowsOrColumnsOrValues', true);
      columns.setEach('isUsedForRowsOrColumnsOrValues', true);
      values.setEach('isUsedForRowsOrColumnsOrValues', true);
      var dateRangeField;
      var dateRangeSettingsField = settingsFields.findBy('name', query.get('dateRangeField'));

      if (dateRangeSettingsField) {
        if (dateRangeSettingsField.get('name') !== DATE_RANGE_FIELD_NAME) {
          var newDateRangeSettingsField = Ember.Object.create(dateRangeSettingsField);
          newDateRangeSettingsField.set('name', DATE_RANGE_FIELD_NAME);
          this.addToSettingsFields(settingsFields, newDateRangeSettingsField);
        }

        dateRangeField = this.get('timestampDataSetFields').findBy('id', dateRangeSettingsField.get('datasetField').toString());
      }

      if (isCreate) {
        var dateRangeFieldId = dataSet.get('settings.dateRangeField');

        if (dateRangeFieldId) {
          dateRangeField = this.get('timestampDataSetFields').findBy('id', dateRangeFieldId.toString());
          dateRangeSettingsField = this.createSettingsField(dateRangeField, null, DATE_RANGE_FIELD_NAME);
          this.addToSettingsFields(settingsFields, dateRangeSettingsField);
        }
      }

      var filterFields = settingsFields.filterBy('isEditable');
      var sortOptions = query.get('sortBy').map(function (item) {
        return Ember.Object.create({
          field: settingsFields.findBy('name', item.get('field')),
          order: item.get('order')
        });
      });

      var _query$get = query.get('filters'),
          matchAll = _query$get.matchAll;

      var matchAllQueryFilters = matchAll.map(function (item) {
        return Ember.Object.create({
          field: item.get('field'),
          predicate: item.get('predicate'),
          value: item.get('value')
        });
      });
      var topNFilter = query.get('topRecordsFilter') ? Ember.Object.create(query.get('topRecordsFilter')) : null;

      if (topNFilter) {
        var topNFilterField = settingsFields.findBy('name', topNFilter.get('field'));
        var topNFilterAggregateField = settingsFields.findBy('name', topNFilter.get('aggregateField'));
        topNFilter.setProperties({
          field: topNFilterField,
          aggregateField: topNFilterAggregateField
        });
      }

      var includeTotals = query.get('includeTotals') || false;
      var includeAverages = query.get('includeAverages') || false;
      var displayType = model.get('displayType');

      if (isCreate) {
        displayType = 'bar_chart';
      }

      var optedOutFilterFields = this.getOptedOutFilterFields(dataSetId);
      var optedOutReportFields = optedOutFilterFields.report;
      var optedOutPageFields = optedOutFilterFields.page;
      var joinsList = (model.get('settings.joinsList') || Ember.A()).map(function (joinSettings) {
        return Ember.Object.create(joinSettings);
      });
      var mergeDataSet = joinsList.get('length') ? this.get('dataSets').findBy('id', joinsList[0].get('secondaryDataSet').toString()) : null; // Benchmarks

      var benchmarks = (model.get('settings.benchmarks') || Ember.A()).map(function (benchmarkItem) {
        return Ember.Object.create(benchmarkItem);
      });
      var visualizationData = Ember.Object.create({
        name: model.get('name') || 'Untitled Visualization',
        displayType: this.get('visualizationTypeOptions').findBy('id', displayType),
        dataSource: dataSourceId,
        dataSet: dataSet,
        mergeDataSet: mergeDataSet,
        rows: rows,
        columns: columns,
        values: values,
        drillDownFields: drillDownFields,
        filterFields: filterFields,
        sortOptions: sortOptions,
        matchAllQueryFilters: matchAllQueryFilters,
        dateRangeField: dateRangeField,
        topNFilter: topNFilter,
        optedOutReportFields: optedOutReportFields,
        optedOutPageFields: optedOutPageFields,
        settingsFields: settingsFields,
        settingsFieldsFilters: settingsFieldsFilters,
        includeTotals: includeTotals,
        includeAverages: includeAverages,
        joinsList: joinsList,
        benchmarks: benchmarks
      });
      var includePercentageOption = this.get('includePercentagesOptions').findBy('id', query.get('includePercentages'));
      this.set('pivotTableIncludePercentageOption', Ember.isPresent(includePercentageOption) ? Ember.Object.create(includePercentageOption) : null);
      this.set('visualizationData', visualizationData);

      if (this.get('isDataTile')) {
        visualizationData.set('valuesAggregation', query.get('valuesAggregation'));
      }

      this.setVisualizationDisplaySettings();
    },
    getSettingsData: function getSettingsData() {
      var isPreview = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var visualizationData = this.get('visualizationData');

      if (visualizationData.displayType.id == 'text_tile') {
        return Ember.Object.create({
          'fields': [],
          'query': Ember.Object.create({
            'rows': [],
            'columns': [],
            'values': [],
            'filters': {
              'matchAll': [],
              'matchAny': []
            },
            'sortBy': [],
            'drillDownFields': []
          })
        });
      }

      var rows = visualizationData.get('rows').mapBy('name');
      var columns = visualizationData.get('columns').mapBy('name');
      var values = visualizationData.get('values').mapBy('name');
      var drillDownFields = visualizationData.get('drillDownFields').mapBy('name');
      var settingsFields = visualizationData.get('settingsFields');
      var dateRangeField = visualizationData.get('dateRangeField');

      if (dateRangeField) {
        dateRangeField = settingsFields.findBy('name', DATE_RANGE_FIELD_NAME);
      }

      var sortOptions = visualizationData.get('sortOptions').map(function (item) {
        return {
          field: item.get('field.name'),
          order: item.get('order')
        };
      });
      var matchAllQueryFilters = visualizationData.get('matchAllQueryFilters');
      var topNFilter = visualizationData.get('topNFilter');

      if (topNFilter) {
        topNFilter = Ember.Object.create(topNFilter);
        var topNFilterField = topNFilter.get('field');
        var topNFilterAggregateField = topNFilter.get('aggregateField');

        if (topNFilterField && topNFilterAggregateField) {
          topNFilter.setProperties({
            field: topNFilterField.get('name'),
            aggregateField: topNFilterAggregateField.get('name')
          });
        }
      }

      var optedOutReportFields = visualizationData.get('optedOutReportFields').mapBy('name');
      var optedOutPageFields = visualizationData.get('optedOutPageFields').mapBy('name');
      settingsFields = visualizationData.get('settingsFields');

      if (!isPreview) {
        settingsFields.forEach(function (field) {
          delete field.isUsedForRowsOrColumnsOrValues;
        });
      }

      var settingsData = Ember.Object.create({
        fields: settingsFields,
        query: Ember.Object.create({
          dataSource: visualizationData.get('dataSource'),
          dataSet: visualizationData.get('dataSet.id'),
          dateRangeField: dateRangeField ? dateRangeField.get('name') : null,
          rows: rows,
          columns: columns,
          drillDownFields: drillDownFields,
          values: values,
          sortBy: sortOptions,
          filters: {
            matchAll: matchAllQueryFilters
          },
          topRecordsFilter: topNFilter,
          optedOutReportFields: optedOutReportFields,
          optedOutPageFields: optedOutPageFields
        }),
        joinsList: visualizationData.get('joinsList'),
        benchmarks: visualizationData.get('benchmarks')
      });

      if (visualizationData.get('displayType.id') == 'pivot_table') {
        settingsData.get('query').setProperties({
          includeTotals: visualizationData.get('displaySettings.showTotals'),
          includeAverages: visualizationData.get('displaySettings.showAverages'),
          includePercentages: Ember.isPresent(this.get('pivotTableIncludePercentageOption')) ? this.get('pivotTableIncludePercentageOption.id') : null
        });
      }

      if (this.get('isDataTile')) {
        var hasRowSelected = rows.length;
        var valuesAggregation = hasRowSelected ? visualizationData.get('valuesAggregation') : null;
        settingsData.get('query').set('valuesAggregation', valuesAggregation);
      }

      if (isPreview) {
        var pageId = this.get('pageId');
        settingsData.set('page', pageId);
      }

      return settingsData;
    },
    reinitiateVisualizationData: function reinitiateVisualizationData(dataSet) {
      var forLoadingState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var existingData = this.get('visualizationData');
      var visualizationData = Ember.Object.create({
        name: existingData.get('name'),
        displayType: existingData.get('displayType'),
        dataSource: dataSet.get('dataSource'),
        dataSet: dataSet,
        mergeDataSet: null,
        rows: Ember.A(),
        columns: Ember.A(),
        values: Ember.A(),
        drillDownFields: Ember.A(),
        filterFields: Ember.A(),
        sortOptions: Ember.A(),
        matchAllQueryFilters: Ember.A(),
        dateRangeField: null,
        topNFilter: null,
        optedOutReportFields: Ember.A(),
        optedOutPageFields: Ember.A(),
        settingsFields: Ember.A(),
        includeTotals: false,
        includeAverages: false,
        includePercentages: null,
        joinsList: Ember.A(),
        benchmarks: Ember.A(),
        settingsFieldsFilters: Ember.Object.create()
      });

      if (this.get('isDataTile')) {
        visualizationData.set('valuesAggregation', null);
      }

      if (!forLoadingState) {
        var dateRangeField;
        var dateRangeFieldId = dataSet.get('settings.dateRangeField');

        if (dateRangeFieldId) {
          dateRangeField = this.get('timestampDataSetFields').findBy('id', dateRangeFieldId.toString());
        }

        var dataSetId = dataSet.get('id');
        var optedOutFilterFields = this.getOptedOutFilterFields(dataSetId);
        var optedOutReportFields = optedOutFilterFields.report;
        var optedOutPageFields = optedOutFilterFields.page;
        visualizationData.setProperties({
          dateRangeField: dateRangeField,
          optedOutReportFields: optedOutReportFields,
          optedOutPageFields: optedOutPageFields
        });

        if (dateRangeField) {
          var settingsFields = visualizationData.get('settingsFields');
          var dateRangeSettingsField = this.createSettingsField(dateRangeField, null, DATE_RANGE_FIELD_NAME);
          this.addToSettingsFields(settingsFields, dateRangeSettingsField);
        }
      }

      this.set('visualizationData', visualizationData);
      this.set('visualizationData.displaySettings', this.initializeDisplaySettings(Ember.Object.create()));
    },
    setVisualizationDisplaySettings: function setVisualizationDisplaySettings() {
      var displaySettings = this.get('model').get('displaySettings');

      if (Ember.isPresent(displaySettings)) {
        this.set('visualizationData.displaySettings', this.initializeDisplaySettings(_.cloneDeep(displaySettings)));
      } else {
        this.set('visualizationData.displaySettings', this.initializeDisplaySettings(Ember.Object.create()));
      }
    },
    createSettingsField: function createSettingsField(dataSetField, parentDataSetField, name) {
      var field = Ember.Object.create({});
      field.setProperties({
        isEditable: false,
        type: "dataset_field",
        dataType: dataSetField.get('dataType'),
        dataset: dataSetField.get('dataset'),
        datasetField: dataSetField.get('id'),
        name: name || (0, _fieldName.getUniqueFieldName)(dataSetField.get('name')),
        displayName: dataSetField.get('displayName') || dataSetField.get('name')
      });

      if (parentDataSetField) {
        field.setProperties({
          'parentDataset': parentDataSetField.get('dataset'),
          'parentDatasetField': parentDataSetField.get('id')
        });
      }

      return field;
    },
    addToSettingsFields: function addToSettingsFields(settingsFields, field) {
      var hasMatchingFields = settingsFields.filterBy('name', field.get('name'));
      settingsFields.removeObjects(hasMatchingFields);
      settingsFields.addObject(field);
    },
    updateSettingsFields: function updateSettingsFields() {
      var visualizationData = this.get('visualizationData');
      var settingsFields = visualizationData.get('settingsFields');
      var rows = visualizationData.get('rows');
      var columns = visualizationData.get('columns');
      var values = visualizationData.get('values');
      var drillDownFields = visualizationData.get('drillDownFields');
      var dateRangeSettingsField = settingsFields.findBy('name', DATE_RANGE_FIELD_NAME);
      var sortOptionsSettingsFields = visualizationData.get('sortOptions').mapBy('field');
      var filterFields = visualizationData.get('filterFields');
      var matchAllFilterFields = visualizationData.get('matchAllQueryFilters').map(function (filter) {
        var fieldName = filter.field;
        var filterSettingsField = settingsFields.findBy('name', fieldName);
        return filterSettingsField;
      }); // add value field filters to value fields

      var fieldFilters = this.get('visualizationData.settingsFieldsFilters');
      var fieldsOfFieldFilters = Ember.A();

      for (var _i = 0, _Object$keys = Object.keys(fieldFilters); _i < _Object$keys.length; _i++) {
        var fieldName = _Object$keys[_i];
        var filters = fieldFilters[fieldName];
        var field = values.findBy('name', fieldName);

        if (field) {
          field.set('filters', {
            matchAll: filters
          });

          var _filterFields = filters.map(function (filter) {
            var fieldName = filter.field;
            var filterSettingsField = settingsFields.findBy('name', fieldName);
            return filterSettingsField;
          });

          fieldsOfFieldFilters.addObjects(_filterFields);
        }
      }

      var newSettingsFields = Ember.A().addObjects(rows).addObjects(columns).addObjects(values).addObjects(drillDownFields).addObjects(sortOptionsSettingsFields).addObjects(filterFields).addObjects(matchAllFilterFields).addObjects(fieldsOfFieldFilters);

      if (dateRangeSettingsField) {
        newSettingsFields.addObject(dateRangeSettingsField);
      }

      var topNFilter = visualizationData.get('topNFilter');

      if (topNFilter) {
        var topNFilterField = topNFilter.get('field');
        var topNFilterAggregateField = topNFilter.get('aggregateField');

        if (topNFilterField && topNFilterAggregateField) {
          newSettingsFields.addObjects([topNFilterField, topNFilterAggregateField]);
        }
      }

      newSettingsFields.setEach('isEditable', false);
      filterFields.setEach('isEditable', true);
      visualizationData.set('settingsFields', newSettingsFields);
    },
    // rectifySortOptions will remove existing sort options whose fields are not present in rows, columns, values
    rectifySortOptions: function rectifySortOptions() {
      var visualizationData = this.get('visualizationData');
      var sortableFields = Ember.A().addObjects(visualizationData.get('rows')).addObjects(visualizationData.get('columns')).addObjects(visualizationData.get('values'));
      var sortOptions = visualizationData.get('sortOptions');
      var sortableFieldsNames = sortableFields.mapBy('name');
      var sortOptionsToRemove = Ember.A();
      sortOptions.forEach(function (item) {
        if (!sortableFieldsNames.includes(item.get('field.name'))) {
          sortOptionsToRemove.addObject(item);
        }
      });
      visualizationData.get('sortOptions').removeObjects(sortOptionsToRemove);
    },

    /* 
      - On Values change, we need to update/remove benchmark `field` as benchmark relies on values.
    */
    modifyBenchmarks: function modifyBenchmarks(newValueFields, oldValueFields) {
      var visualizationData = this.get('visualizationData');
      var benchmarks = visualizationData.get('benchmarks');

      if (newValueFields.length < oldValueFields.length) {
        // When values are deleted
        var _oldValueFields$filte = oldValueFields.filter(function (item) {
          return newValueFields.indexOf(item) == -1;
        }),
            _oldValueFields$filte2 = _slicedToArray(_oldValueFields$filte, 1),
            fieldRemoved = _oldValueFields$filte2[0];

        var benchmarksToRemove = benchmarks.filterBy('field.name', fieldRemoved.name);

        if (benchmarksToRemove) {
          benchmarks.removeObjects(benchmarksToRemove);
        }
      }

      if (newValueFields.length == oldValueFields.length) {
        // When values are edited
        var modifiedFields = {};
        newValueFields.forEach(function (item, index) {
          if (oldValueFields.indexOf(item) == -1) {
            modifiedFields.newField = item;
            modifiedFields.oldField = oldValueFields[index];
          }
        });
        var newField = modifiedFields.newField,
            oldField = modifiedFields.oldField;
        var benchmarksToModify = benchmarks.filterBy('field.name', oldField.name);
        (benchmarksToModify || []).forEach(function (benchmark) {
          benchmark.field.set('name', newField.name);
          benchmark.field.set('displayName', newField.displayName || newField.name);
        });
      }
    },
    dataSetChangeHelper: function dataSetChangeHelper(dataSet) {
      var _this3 = this;

      var dataSourceId = dataSet.get('dataSource');
      var dataSetId = dataSet.get('id');
      this.set('isLoadingDependencies', true);
      this.reinitiateVisualizationData(dataSet, true);
      return this.loadDependencies(dataSourceId, dataSetId).then(function () {
        _this3.reinitiateVisualizationData(dataSet);

        if (_this3.validateVisualizationSettings()) {
          _this3.triggerPreviewReload();
        }
      }).finally(function () {
        _this3.set('isLoadingDependencies', false);
      });
    },
    getOptedOutFilterFields: function getOptedOutFilterFields(visualizationDataSetId) {
      var reportLevelFields = this.reportLevelFields,
          pageLevelFields = this.pageLevelFields,
          query = this.query;
      var optedOutReportFields = (query.get('optedOutReportFields') || Ember.A()).map(function (fieldName) {
        return reportLevelFields.findBy('name', fieldName);
      }); // Needed to remove undefined which will be resulted from opted out field names of removed fields in report

      optedOutReportFields = optedOutReportFields.compact();
      reportLevelFields.forEach(function (field) {
        if (field.parentDataset) {
          if (field.parentDataset !== Number(visualizationDataSetId)) {
            optedOutReportFields.addObject(field);
          }
        } else if (field.dataset !== Number(visualizationDataSetId)) {
          optedOutReportFields.addObject(field);
        }
      });
      var optedOutPageFields = (query.get('optedOutPageFields') || Ember.A()).map(function (fieldName) {
        return pageLevelFields.findBy('name', fieldName);
      }); // Needed to remove undefined which will be resulted from opted out field names of removed fields in page

      optedOutPageFields = optedOutPageFields.compact();
      pageLevelFields.forEach(function (field) {
        if (field.parentDataset) {
          if (field.parentDataset !== Number(visualizationDataSetId)) {
            optedOutPageFields.addObject(field);
          }
        } else if (field.dataset !== Number(visualizationDataSetId)) {
          optedOutPageFields.addObject(field);
        }
      });
      return {
        report: optedOutReportFields,
        page: optedOutPageFields
      };
    },
    actions: {
      getPreviewData: function getPreviewData() {
        var _this4 = this;

        var settings = this.getSettingsData(true);
        this.set('isPreviewDataLoading', true);
        return this.get('dataService').getVisualizationData({
          settings: settings
        }).then(function (data) {
          _this4.setProperties({
            previewData: data,
            isPreviewDataRequestFailure: false
          });

          _this4.setVisualizationDataViewModel(_this4.get('currentVisualizationDataView'));

          return data;
        }).catch(function (_ref) {
          var errorResponse = _ref.errorResponse;

          _this4.set('isPreviewDataRequestFailure', true);

          throw errorResponse;
        }).finally(function () {
          _this4.set('isPreviewDataLoading', false);
        });
      },
      onNameChange: function onNameChange() {
        var changedName = this.get('visualizationData.name');
        this.get('previewModel').set('name', changedName);
      },
      onDisplayTypeChange: function onDisplayTypeChange(displayType) {
        this.set('visualizationData.displayType', displayType);
        this.createDisplaySettingsOnDisplayTypeChange(); //FIXME: Need to have this includePercentageOption as a part of display settings

        this.set('pivotTableIncludePercentageOption', null);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }

        this.initializeVisualizationDataView();
      },
      applyDateRange: function applyDateRange(data) {
        this.saveDateRange(data); //Calling from Mixin
      },
      setCurrentTab: function setCurrentTab(value) {
        this.set('currentTabValue', value);
      },
      onDataSetChange: function onDataSetChange(dataSetOption) {
        if (dataSetOption.isCombineTrigger) {
          this.set('showMergeDataSetsModal', true);
        } else {
          this.dataSetChangeHelper(dataSetOption);
        }
      },
      onDateRangeFieldChange: function onDateRangeFieldChange(field) {
        var dateRangeField = field === UNSELECT_FIELD ? null : field;
        this.set('visualizationData.dateRangeField', dateRangeField);
        var settingsFields = this.get('visualizationData.settingsFields');
        settingsFields = settingsFields.rejectBy('name', DATE_RANGE_FIELD_NAME);
        this.set('visualizationData.settingsFields', settingsFields);

        if (dateRangeField) {
          var parentField = null;

          if (dateRangeField.parentDatasetField) {
            parentField = Ember.Object.create({
              'dataset': dateRangeField.get('parentDataset'),
              'id': dateRangeField.get('parentDatasetField')
            });
          }

          var dateRangeSettingsField = this.createSettingsField(dateRangeField, parentField, DATE_RANGE_FIELD_NAME);
          this.addToSettingsFields(settingsFields, dateRangeSettingsField);
        }

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onRowsChange: function onRowsChange(rows) {
        var canGenerateDisplaySettings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        rows.setEach('isUsedForRowsOrColumnsOrValues', true);
        this.set('visualizationData.rows', rows);
        this.rectifySortOptions();
        this.updateSettingsFields();

        if (canGenerateDisplaySettings) {
          this.generateDisplaySettings('rows');
        }

        if (this.validateVisualizationSettings()) {
          this.initializeVisualizationDataView();
          this.triggerPreviewReload();
        }
      },
      onColumnsChange: function onColumnsChange(columns) {
        var canGenerateDisplaySettings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        columns.setEach('isUsedForRowsOrColumnsOrValues', true);
        this.set('visualizationData.columns', columns);
        this.rectifySortOptions();
        this.updateSettingsFields();

        if (canGenerateDisplaySettings) {
          this.generateDisplaySettings('columns');
        }

        if (this.validateVisualizationSettings()) {
          this.initializeVisualizationDataView();
          this.triggerPreviewReload();
        }
      },
      onValuesChange: function onValuesChange(values) {
        var _this5 = this;

        var canGenerateDisplaySettings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        values.setEach('isUsedForRowsOrColumnsOrValues', true);
        values.forEach(function (value) {
          if (value.filters) {
            var matchAllFilters = value.filters.matchAll;

            _this5.set("visualizationData.settingsFieldsFilters.".concat(value.name), matchAllFilters);
          }
        });

        if (this.canManageBenchmarks && Ember.isPresent(this.get('visualizationData.benchmarks'))) {
          this.modifyBenchmarks(values, this.get('visualizationData.values'));
        }

        this.set('visualizationData.values', values);
        this.rectifySortOptions(); // Adding default sort option if not sort options are present
        // FIXME: Based on each visualization, default sort options added should change

        var sortOptions = this.get('visualizationData.sortOptions');

        if (values.get('length') && (!sortOptions || !sortOptions.get('length'))) {
          var field = values.get('lastObject');
          var sortOption = Ember.Object.create({
            field: field,
            order: 'descending'
          });
          this.get('visualizationData').set('sortOptions', Ember.A().addObject(sortOption));
        }

        this.updateSettingsFields();

        if (canGenerateDisplaySettings) {
          this.generateDisplaySettings('values');
        }

        if (this.validateVisualizationSettings()) {
          this.initializeVisualizationDataView();
          this.triggerPreviewReload();
        }
      },
      onTextTileValueChange: function onTextTileValueChange(value) {
        var displaySettings = this.visualizationData.displaySettings;
        displaySettings.set('text', value);
        this.send('onDisplaySettingsUpdate');
      },
      // Populates field, agg.field for Top-N-Filter based on rows, columns, values
      populateTopNFilter: function populateTopNFilter() {
        var rowFields = this.get('visualizationData.rows');
        var columnFields = this.get('visualizationData.columns');
        var valueFields = this.get('visualizationData.values');
        var topNFilter = Ember.Object.create({
          field: null,
          aggregateField: null
        });

        if (Ember.isPresent(rowFields) || Ember.isPresent(columnFields)) {
          var topNFilterField = rowFields.length ? rowFields : columnFields;
          var isTimeStampField = topNFilterField.get('firstObject').dataType == 'timestamp';

          if (!isTimeStampField) {
            topNFilter.set('field', topNFilterField.get('firstObject'));
          }
        }

        if (Ember.isPresent(valueFields)) {
          topNFilter.set('aggregateField', valueFields.get('firstObject'));
        }

        return topNFilter;
      },
      removeFilterField: function removeFilterField(field) {
        var filterFields = this.get('visualizationData.filterFields');
        filterFields.removeObject(field);
        this.updateSettingsFields();
      },
      toggleAddFilterField: function toggleAddFilterField() {
        this.toggleProperty('showAddFilterField');
      },
      addFilterField: function addFilterField(field) {
        var filterFields = this.get('visualizationData.filterFields');
        filterFields.addObject(field);
        this.toggleProperty('showAddFilterField');
        this.updateSettingsFields();
      },
      editFilterField: function editFilterField(newField, oldField) {
        var filterFields = this.get('visualizationData.filterFields');
        var indexOfOldField = filterFields.indexOf(oldField);
        filterFields.removeObject(oldField);
        filterFields.insertAt(indexOfOldField, newField);
        this.updateSettingsFields();
      },
      applyFilters: function applyFilters(filters) {
        this.set('visualizationData.matchAllQueryFilters', filters);
        this.updateSettingsFields();

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      applyBenchmarks: function applyBenchmarks(benchmarks) {
        this.set('visualizationData.benchmarks', benchmarks);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      applyTableFormatting: function applyTableFormatting(formats) {
        this.set('visualizationData.displaySettings.conditionalFormatting', formats);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onTopNFilterChange: function onTopNFilterChange(filter) {
        this.set('visualizationData.topNFilter', filter);
        this.updateSettingsFields();

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      // FIXME: Currently, 'includePercentages' has different formats in displaySettings and  query
      // Hence needed separate property and an action (DDAU) to handle it
      onUpdatePivotTableIncludePercentage: function onUpdatePivotTableIncludePercentage(percentageOption) {
        this.set('pivotTableIncludePercentageOption', percentageOption);
      },
      onDisplaySettingsUpdate: function onDisplaySettingsUpdate() {
        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('canShowFieldModal');
      },
      setCurrentVisualizationDataView: function setCurrentVisualizationDataView(view) {
        this.set('currentVisualizationDataView', view);
        this.setVisualizationDataViewModel(view);
      },
      save: function save() {
        var _this6 = this;

        if (this.validateVisualizationSettings()) {
          var visualizationData = this.get('visualizationData');
          var model = this.get('model');
          var settings = this.getSettingsData();
          var displaySettings = this.get('visualizationData.displaySettings');
          var isCreate = this.isCreate;
          model.setProperties({
            name: visualizationData.get('name'),
            displayType: visualizationData.get('displayType.id'),
            settings: settings,
            displaySettings: displaySettings
          });

          if (isCreate) {
            model.set('page', this.get('pageId'));
          }

          model.save().then(function () {
            var pageId = _this6.get('pageId');

            var reportId = _this6.get('reportId');

            _this6.transitionToRoute('reports.details.pages.details', reportId, pageId);
          }).catch(function (_ref2) {
            var errors = _ref2.errors;
            var nonFieldErrors = errors.nonFieldErrors;

            if (Ember.isPresent(nonFieldErrors)) {
              var errorText = '';
              nonFieldErrors.forEach(function (error) {
                errorText = "".concat(errorText).concat(error, "\n");
              });

              _this6.toastMessageService.showMessage({
                type: 'failure',
                text: errorText,
                isMultiLine: nonFieldErrors.length > 1 ? true : false
              });
            }
          });
        }
      },
      cancel: function cancel() {
        var reportId = this.get('reportId');
        var pageId = this.get('pageId');
        this.transitionToRoute('reports.details.pages.details', reportId, pageId);
      },
      fetchDataSetFields: function fetchDataSetFields() {
        var _this7 = this;

        return new Ember.RSVP.Promise(function (resolve) {
          var data = {
            'datasetFields': _this7.get('dataSetFields'),
            'relatedDataSetFieldsMap': _this7.get('relatedDataSetFieldsMap'),
            // TODO: this is temporary, please check appropriate way to send dataset to add-visualization-field component.
            'dataset': _this7.get('visualizationData.dataSet')
          };

          if (_this7.get('featuresService.combineDataSets')) {
            data.joinsList = _this7.get('visualizationData.joinsList');
          }

          return resolve(data);
        });
      },
      resetSeekedDateRange: function resetSeekedDateRange() {},
      onReportFieldsOptInChange: function onReportFieldsOptInChange(optInFields) {
        var reportLevelFields = this.get('reportLevelFields');
        var optedOutFields = reportLevelFields.slice().removeObjects(optInFields);
        this.set('visualizationData.optedOutReportFields', optedOutFields);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onPageFieldsOptInChange: function onPageFieldsOptInChange(optInFields) {
        var pageLevelFields = this.get('pageLevelFields');
        var optedOutFields = pageLevelFields.slice().removeObjects(optInFields);
        this.set('visualizationData.optedOutPageFields', optedOutFields);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onSortOptionsChange: function onSortOptionsChange() {
        this.updateSettingsFields();

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onValuesAggregationChange: function onValuesAggregationChange(aggregationDetails) {
        this.set('visualizationData.valuesAggregation', aggregationDetails);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      toggleAddDrillDownField: function toggleAddDrillDownField() {
        this.toggleProperty('showAddDrillDownField');
      },
      addDrillDownField: function addDrillDownField(field) {
        var drillDownFields = this.get('visualizationData.drillDownFields');
        drillDownFields.addObject(field);
        this.toggleProperty('showAddDrillDownField');
        this.updateSettingsFields();
      },
      removeDrillDownField: function removeDrillDownField(field) {
        var drillDownFields = this.get('visualizationData.drillDownFields');
        drillDownFields.removeObject(field);
        this.updateSettingsFields();
      },
      editDrillDownField: function editDrillDownField(newField, oldField) {
        var drillDownFields = this.get('visualizationData.drillDownFields');
        var indexOfOldField = drillDownFields.indexOf(oldField);
        drillDownFields.removeObject(oldField);
        drillDownFields.insertAt(indexOfOldField, newField);
        this.updateSettingsFields();
      },
      onJoinsSettingsChange: function onJoinsSettingsChange(joinsList) {
        var _this8 = this;

        var dataSetId = joinsList.get('firstObject.primaryDataSet');
        var dataSet = this.get('dataSets').findBy('id', dataSetId.toString());
        this.dataSetChangeHelper(dataSet).then(function () {
          var mergeDataSetId = joinsList.get('firstObject.secondaryDataSet');

          var mergeDataSet = _this8.get('dataSets').findBy('id', mergeDataSetId.toString());

          _this8.setProperties({
            'visualizationData.joinsList': joinsList,
            'visualizationData.mergeDataSet': mergeDataSet,
            'showMergeDataSetsModal': false
          });
        });
      },
      closeMergeDataSetsModal: function closeMergeDataSetsModal() {
        this.set('showMergeDataSetsModal', false);
      },
      openMergeDataSetsModal: function openMergeDataSetsModal() {
        this.set('showMergeDataSetsModal', true);
      }
    }
  });

  _exports.default = _default;
});