define("happyfox-bi/login/user-credentials/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    requestService: Ember.inject.service('request'),
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    email: null,
    password: null,
    isLoginInProgress: false,
    showError: false,
    errorMessage: null,
    isSubscriptionExpired: false,
    disableSubmit: Ember.computed('isLoginInProgress', 'email', 'password', {
      get: function get() {
        var email = this.get('email');
        var password = this.get('password');
        var hasRequiredDetails = Ember.isPresent(email) && Ember.isPresent(password);
        return this.get('isLoginInProgress') || !hasRequiredDetails || this.get('isSubscriptionExpired');
      }
    }),
    actions: {
      login: function login() {
        var _this = this;

        this.setProperties({
          isLoginInProgress: true,
          showError: false
        });
        var email = this.get('email');
        var password = this.get('password');
        this.get('dataService').staffLogin(email, password).then(function () {
          _this.bootApp();
        }).catch(function (_ref) {
          var status = _ref.status,
              errors = _ref.errors;

          if (status === 401 || status === 403) {
            var errorMessage = errors.error;

            if (!Ember.isPresent(errorMessage)) {
              errorMessage = _this.intlService.lookup('login-error');
            }

            _this.setProperties({
              'showError': true,
              'errorMessage': errors.error
            });
          }
        }).finally(function () {
          _this.set('isLoginInProgress', false);
        });
      },
      goToResetPassword: function goToResetPassword() {
        this.goToResetPassword();
      }
    }
  });

  _exports.default = _default;
});