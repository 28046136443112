define("happyfox-bi/data-sources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LOJc6wcU",
    "block": "{\"symbols\":[\"dataSource\"],\"statements\":[[10,\"section\"],[14,0,\"data-sources-navigation content_outlet_left_nav\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"navigation-pane_data-sources_header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"navigation-pane_data-sources_header_title\"],[12],[2,\"\\n      \"],[11,\"span\"],[24,0,\"u-cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"goToDataSources\"],null],[12],[1,[30,[36,4],[\"data-sources\"],null]],[13],[2,\"\\n\"],[6,[37,5],[[32,0,[\"canManageDataSources\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"navigation-pane_data-sources_header_add_svg icon-btn mod-data-sources-add-icon u-cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleCreateDataSource\"],null],[12],[1,[30,[36,3],[\"plus\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[6,[37,5],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"toggleCreateDataSource\",\"onDataSourceCreation\"],[[30,[36,0],[[32,0],\"toggleCreateDataSource\"],null],[30,[36,0],[[32,0],\"reloadDataSources\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"class\",\"dataSource\",\"goToDataSets\",\"canCreateDataSet\"],[\"navigation-pane_data-sources_group\",[32,1],[30,[36,0],[[32,0],\"goToDataSets\"],null],[32,0,[\"canManageDataSources\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"data-sources-content content_outlet_main\"],[12],[2,\"\\n  \"],[1,[30,[36,11],[[30,[36,10],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"data-sources/components/data-source\",\"data-sources/components/create-data-source\",\"inline-svg\",\"t\",\"if\",\"showCreateDataSource\",\"dataSources\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "happyfox-bi/data-sources/template.hbs"
    }
  });

  _exports.default = _default;
});