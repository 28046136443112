define("happyfox-bi/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c6GLcWH2",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"bootApp\",\"goToResetPassword\",\"isSubscriptionExpired\"],[[30,[36,1],[[32,0],\"bootApp\"],null],[30,[36,1],[[32,0],\"goToResetPassword\"],null],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isSubscriptionExpired\",\"action\",\"login/user-credentials\"]}",
    "meta": {
      "moduleName": "happyfox-bi/login/template.hbs"
    }
  });

  _exports.default = _default;
});