define("happyfox-bi/data-sources/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DATA_SOURCE_SYNC_STATUS = void 0;
  var DATA_SOURCE_SYNC_STATUS = {
    IN_PROGRESS: 'in_progress',
    SUCCESS: 'success',
    FAILURE: 'failed'
  };
  _exports.DATA_SOURCE_SYNC_STATUS = DATA_SOURCE_SYNC_STATUS;
});