define("happyfox-bi/manage/account-settings/form-model", ["exports", "happyfox-bi/form-models/base-form", "happyfox-bi/components/my-settings/constants"], function (_exports, _baseForm, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    featuresService: Ember.inject.service('features'),
    init: function init() {
      this._super.apply(this, arguments);

      var rawFields = Ember.A();
      var intlService = this.get('intlService');
      var featuresService = this.get('featuresService');
      var timezoneField = Ember.Object.create({
        key: 'timezone',
        isChoice: true,
        canSetDefaultChoiceValue: false,
        isMandatory: true,
        choices: _constants.TIMEZONES,
        choiceLabelKey: 'name',
        placeholder: intlService.lookup('select-timezone'),
        label: intlService.lookup('timezone'),
        isDisabled: !featuresService.get('manageAccountSettings')
      });
      rawFields.addObjects([timezoneField]);
      this.setProperties({
        fields: this._createFieldModels(rawFields),
        basicFieldKeys: rawFields.mapBy('key')
      });

      this._copyFromDataSource();

      this._super.apply(this, arguments);
    },
    generatePayload: function generatePayload() {
      var payloadData = this.getModifiedFields();
      return payloadData;
    },
    submit: function submit() {
      var _this = this;

      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.validate().then(function () {
          _this._copyToDataSource();

          var payload = _this.generatePayload();

          resolve(payload);
        }).catch(function () {
          _this.scrollToError();

          reject();
        });
      });
    }
  });

  _exports.default = _default;
});