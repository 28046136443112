define("happyfox-bi/data-sources/components/table-cell/on-demand-sync/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u4kZ1SEA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"canShowSyncFeature\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"data-sources/components/on-demand-sync-button\",[],[[\"@status\",\"@dataSourceId\",\"@dataSourceName\",\"@onSyncInitiated\"],[[32,0,[\"status\"]],[32,0,[\"dataSourceId\"]],[32,0,[\"dataSourceName\"]],[30,[36,0],[[32,0],\"onSyncInitiated\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/data-sources/components/table-cell/on-demand-sync/template.hbs"
    }
  });

  _exports.default = _default;
});