define("happyfox-bi/components/value-filter-field-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dTU8Ly84",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,6],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"section\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,1],[\"widget-filters.select-data-set-field\"],null]],[13],[2,\"\\n\"],[6,[37,5],null,[[\"tagName\",\"class\",\"dropdownClass\",\"options\",\"selected\",\"placeholder\",\"onChange\",\"searchEnabled\",\"searchField\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete widget-filters_content_item_field\",\"mod-push-dropdown-up\",[35,4],[35,3],[30,[36,1],[\"widget-filters.select-data-set-field\"],null],[30,[36,2],[[32,0],\"onDataSetFieldChange\"],null],true,\"displayNameOrName\",true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"displayNameOrName\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"ball-triangle-loader\"],[[\"class\"],[\"mod-loading-options\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"t\",\"action\",\"dataSetField\",\"dataSetFieldOptions\",\"power-select\",\"if\",\"loadingOptions\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/value-filter-field-selection/template.hbs"
    }
  });

  _exports.default = _default;
});