define("happyfox-bi/components/widget-filters/filter/component", ["exports", "happyfox-bi/utilities/date", "happyfox-bi/utilities/number"], function (_exports, _date, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var TIME_UNIT_OPTIONS = ['minutes', 'hours', 'days'];
  var DEFAULT_TIME_OPTION = 'hours';
  var BOOLEAN_OPTIONS = ['true', 'false'];
  var RELATIVE_DATE_PREDICATES = ['within last n days', 'within next n days', 'before last n days', 'after next n days'];

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['widget-filters_content_item', 'selected-item-container'],
    classNameBindings: ['isAdd:mod-add-filter-item'],
    dataService: Ember.inject.service('data'),
    fields: null,
    filter: null,
    editedPredicate: null,
    editedValue: null,
    originalValue: null,
    isAdd: false,
    unAppliedFilterFields: null,
    selectedField: null,
    prefetchedFiltersValueOptions: null,
    valueFieldDetails: null,
    // { 'isText': true, 'areOptionsLoading': true, 'useAutoComplete': false, 'options': ['OP1'], isError: false }
    visualizationId: null,
    timeUnitOptions: TIME_UNIT_OPTIONS,
    booleanOptions: BOOLEAN_OPTIONS,
    searchParam: 'text',
    fieldSelectionComponent: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.resetEditedValues();
      this.setValueFieldDetails();
    },
    field: Ember.computed('fields.[]', 'filter.field', {
      get: function get() {
        if (!this.get('isAdd')) {
          return this.get('fields').findBy('name', this.get('filter.field'));
        }
      }
    }),
    fieldDisplayName: Ember.computed('field.{displayName,name}', {
      get: function get() {
        var field = this.get('field');
        return field.get('displayName') || field.get('name');
      }
    }),
    predicateOptions: Ember.computed('field.dataType', 'selectedField.dataType', {
      get: function get() {
        var type = this.get('field.dataType') || this.get('selectedField.dataType');

        switch (type) {
          case 'text':
            return ['is', 'is not', 'contains', 'does not contain', 'is set', 'is not set'];

          case 'int':
          case 'decimal':
          case 'currency':
          case 'time':
            return ['is', 'is not', 'less than', 'less than or equal to', 'greater than', 'greater than or equal to', 'is set', 'is not set'];

          case 'date':
          case 'timestamp':
            {
              var format = this.get('field.format') || this.get('selectedField.format');
              var stringFormats = ["day_of_week", "hour_of_day", "minute", "month", "week_of_month", "quarter", "quarter_with_year"];

              if (stringFormats.includes(format)) {
                return ['is', 'is not', 'is set', 'is not set'];
              }

              return ['is', 'is not', 'less than', 'less than or equal to', 'greater than', 'greater than or equal to', 'is set', 'is not set', 'within last n days', 'within next n days', 'before last n days', 'after next n days'];
            }

          case 'boolean':
            return ['is', 'is not', 'is set', 'is not set'];

          case 'list':
            return ['contains', 'does not contain', 'is empty', 'is not empty'];
        }
      }
    }),
    singleValueFieldType: Ember.computed('selectedField.dataType', {
      get: function get() {
        var type = this.get('selectedField.dataType');
        var singleValueFieldTypes = ['int', 'decimal', 'time', 'date', 'timestamp', 'boolean', 'currency'];
        return singleValueFieldTypes.includes(type);
      }
    }),
    displayValue: Ember.computed('originalValue', {
      get: function get() {
        var field = this.get('field') || this.get('selectedField');
        var type = field.dataType;
        var value = this.get('originalValue');

        switch (type) {
          case 'text':
          case 'list':
            return Ember.isPresent(value) ? value.join(', ') : value;

          case 'int':
          case 'decimal':
          case 'boolean':
            return value;

          case 'currency':
            return Ember.isPresent(value) ? (0, _number.getCurrencyValue)(value, field.currencyUnit, field.currencyFormat) : value;

          case 'time':
            if (value) {
              return "".concat(value.value, " ").concat(value.unit);
            }

            break;

          case 'date':
          case 'timestamp':
            if (this.get('filter.predicate') === 'between') {
              return "".concat((0, _date.getFormattedDate)(value.from), " and ").concat((0, _date.getFormattedDate)(value.to));
            } else if (RELATIVE_DATE_PREDICATES.includes(this.get('filter.predicate'))) {
              return value;
            } else {
              return (0, _date.getFormattedDate)(value);
            }

        }
      }
    }),
    isNoValuePredicate: Ember.computed('editedPredicate', {
      get: function get() {
        var editedPredicate = this.get('editedPredicate');
        var noValuePredicates = ['is set', 'is not set', 'is empty', 'is not empty'];
        return noValuePredicates.includes(editedPredicate);
      }
    }),
    showValueField: Ember.computed('field', 'selectedField', 'isNoValuePredicate', {
      get: function get() {
        return (this.get('field') || this.get('selectedField')) && !this.get('isNoValuePredicate');
      }
    }),
    isDirty: Ember.computed('editedPredicate', 'editedValue', 'editedValue.{value,unit,from,to}', {
      get: function get() {
        var editedValue = this.get('editedValue');
        editedValue = this.copyValue(editedValue);
        editedValue = Ember.isArray(editedValue) ? editedValue.slice().sort() : editedValue;
        var originalValue = this.get('originalValue');
        originalValue = Ember.isArray(originalValue) ? originalValue.slice().sort() : originalValue;
        return this.get('editedPredicate') !== this.get('filter.predicate') || JSON.stringify(editedValue) !== JSON.stringify(originalValue);
      }
    }),
    hasRequiredInformation: Ember.computed('field', 'selectedField', 'editedPredicate', 'editedValue', {
      get: function get() {
        var field = this.get('field') || this.get('selectedField');
        var editedPredicate = this.get('editedPredicate');
        var editedValue = this.get('editedValue');
        var isNoValuePredicate = this.get('isNoValuePredicate');
        return Ember.isPresent(field) && Ember.isPresent(editedPredicate) && (isNoValuePredicate || Ember.isPresent(editedValue));
      }
    }),
    isDateFieldPredicateBetween: Ember.computed('valueFieldDetails.{isDate,isTimestamp}', 'editedPredicate', {
      get: function get() {
        return (this.get('valueFieldDetails.isDate') || this.get('valueFieldDetails.isTimestamp')) && this.get('editedPredicate') === 'between';
      }
    }),
    isCurrentFilterRelativeDate: Ember.computed('field.dataType', 'filter.predicate', {
      get: function get() {
        var fieldType = this.get('field.dataType');
        var predicate = this.get('filter.predicate');
        return (fieldType === 'date' || fieldType === 'timestamp') && RELATIVE_DATE_PREDICATES.includes(predicate);
      }
    }),
    isDateFieldRelativePredicate: Ember.computed('valueFieldDetails.{isDate,isTimestamp}', 'editedPredicate', {
      get: function get() {
        return (this.get('valueFieldDetails.isDate') || this.get('valueFieldDetails.isTimestamp')) && RELATIVE_DATE_PREDICATES.includes(this.get('editedPredicate'));
      }
    }),
    fieldOptionsRemoteUrl: Ember.computed('valueFieldDetails.useAutoComplete', 'field', 'selectedField', 'visualizationId', {
      get: function get() {
        if (this.get('valueFieldDetails.useAutoComplete')) {
          var field = this.get('field') || this.get('selectedField');
          var baseUrl = '/field-options/autocomplete/';

          if (field.get('type') === 'dataset_field') {
            return "".concat(baseUrl, "?field_id=").concat(field.get('datasetField'));
          } else {
            var visualizationId = this.get('visualizationId');
            return "".concat(baseUrl, "?visualization_id=").concat(visualizationId, "&field_name=").concat(field.get('name'));
          }
        }
      }
    }),
    canAddUserTextAsValue: Ember.computed('editedPredicate', 'valueFieldDetails.{isText,isDropdown}', {
      get: function get() {
        var currentPredicate = this.get('editedPredicate');
        var valueFieldDetails = this.get('valueFieldDetails');
        return (currentPredicate === 'contains' || currentPredicate === 'does not contain') && (valueFieldDetails.get('isText') || valueFieldDetails.get('isDropdown'));
      }
    }),
    isValueFieldNumeric: Ember.computed('valueFieldDetails.{isInt,isDecimal,isCurrency}', {
      get: function get() {
        var valueFieldDetails = this.valueFieldDetails;
        var isInt = valueFieldDetails.isInt,
            isCurrency = valueFieldDetails.isCurrency,
            isDecimal = valueFieldDetails.isDecimal;
        return isInt || isDecimal || isCurrency;
      }
    }),
    resetEditedValues: function resetEditedValues() {
      if (this.get('isAdd')) {
        this.setProperties({
          editedPredicate: null,
          editedValue: null,
          originalValue: null,
          selectedField: null
        });
      } else {
        this.setProperties({
          editedPredicate: this.get('filter.predicate'),
          editedValue: this.copyValue(this.get('filter.value')),
          originalValue: this.copyValue(this.get('filter.value'))
        });
      }
    },
    copyValue: function copyValue(value) {
      var type = this.get('field.dataType') || this.get('selectedField.dataType');

      switch (type) {
        case 'text':
        case 'list':
          return Ember.isPresent(value) ? value.slice() : value;

        case 'int':
        case 'decimal':
        case 'currency':
          return Ember.isPresent(value) ? value.toString() : value;

        case 'time':
          if (value) {
            value = Object.assign({}, value);
            value.value = Ember.isPresent(value.value) ? value.value.toString() : value.value;
            return value;
          }

          break;

        case 'boolean':
          if (typeof value === 'boolean') {
            return value ? 'true' : 'false';
          }

          return value;

        case 'date':
        case 'timestamp':
          if (value !== null && _typeof(value) === 'object') {
            return Object.assign({}, value);
          } else {
            return value;
          }

      }
    },
    setValueFieldDetails: function setValueFieldDetails() {
      var valueFieldDetails = Ember.Object.create({
        areOptionsLoading: false,
        useAutoComplete: false,
        options: null,
        isError: false
      });
      this.set('valueFieldDetails', valueFieldDetails);
    },
    fetchFieldOptionsHelper: function fetchFieldOptionsHelper() {
      var _this = this;

      var details = this.get('valueFieldDetails');
      var field = this.get('field') || this.get('selectedField');
      var fieldName = field.get('name');
      var fieldType = field.get('dataType');
      details.set("is".concat(fieldType.classify()), true);

      if (details.get('isText') || details.get('isDropdown') || details.get('isMultipleOption') || details.get('isList')) {
        var prefetchedOptions = this.get("prefetchedFiltersValueOptions.".concat(fieldName));

        if (prefetchedOptions) {
          var hasPaginatedData = prefetchedOptions.meta.next;
          details.setProperties({
            'useAutoComplete': hasPaginatedData,
            'options': prefetchedOptions.results
          });
        } else {
          details.set('areOptionsLoading', true);
          var visualizationId = this.get('visualizationId');
          this.get('dataService').getFieldOptions(field, visualizationId).then(function (data) {
            data.results = data.results.compact(); // Removes `null`

            _this.set("prefetchedFiltersValueOptions.".concat(fieldName), data);

            var hasPaginatedData = data.meta.next;
            details.setProperties({
              'useAutoComplete': hasPaginatedData,
              'options': data.results
            });
          }).catch(function () {
            details.set('isError', true);
          }).finally(function () {
            details.set('areOptionsLoading', false);
          });
        }
      }
    },
    deserializeValue: function deserializeValue() {
      var editedValue = this.get('editedValue');
      var field = this.get('field') || this.get('selectedField');
      var fieldDataType = field.get('dataType');

      if (fieldDataType === 'boolean' && Ember.isPresent(editedValue)) {
        return editedValue === 'true' ? true : false;
      } else {
        return editedValue;
      }
    },
    actions: {
      onPredicateChange: function onPredicateChange(predicate) {
        var existingPredicate = this.get('editedPredicate');
        this.set('editedPredicate', predicate);
        var isCurrentDatePredicateBetween = this.get('isDateFieldPredicateBetween');

        if ((this.get('valueFieldDetails.isDate') || this.get('valueFieldDetails.isTimestamp')) && (!RELATIVE_DATE_PREDICATES.includes(existingPredicate) && RELATIVE_DATE_PREDICATES.includes(predicate) || RELATIVE_DATE_PREDICATES.includes(existingPredicate) && !RELATIVE_DATE_PREDICATES.includes(predicate))) {
          this.set('editedValue', null);
        }

        if (this.get('isNoValuePredicate') || isCurrentDatePredicateBetween && !this.get('isDateFieldPredicateBetween')) {
          this.set('editedValue', null);
        } else if (this.get('isDateFieldPredicateBetween')) {
          this.set('editedValue', {
            from: null,
            to: null
          });
        }

        var selectedField = this.get('selectedField') || this.get('field');
        var isTimeField = selectedField.get('dataType') === 'time';

        if (isTimeField) {
          if (predicate === 'is set' || predicate === 'is not set') {
            this.set('editedValue', null);
          } else if (!this.get('editedValue').value) {
            this.set('editedValue', {
              value: null,
              unit: DEFAULT_TIME_OPTION
            });
          }
        }
      },
      update: function update(popOver) {
        var editedValue = this.deserializeValue();
        var data = Ember.Object.create({
          field: this.get('filter.field'),
          predicate: this.get('editedPredicate'),
          value: editedValue
        });
        this.updateFilter(data);

        if (!this.isDestroyed) {
          popOver.closePopOver();
        }
      },
      add: function add(popOver) {
        var selectedField = this.get('selectedField');

        if (this.get('fieldSelectionComponent')) {
          this.addSelectedField(selectedField);
        }

        var editedValue = this.deserializeValue();
        var data = Ember.Object.create({
          field: selectedField.get('name'),
          predicate: this.get('editedPredicate'),
          value: editedValue
        });
        this.addFilter(data);

        if (!this.isDestroyed) {
          popOver.closePopOver();
        }
      },
      cancel: function cancel(popOver) {
        this.resetEditedValues();
        popOver.closePopOver();
      },
      onClosePopOver: function onClosePopOver() {
        this.resetEditedValues();
      },
      onFieldChange: function onFieldChange(field) {
        this.set('selectedField', field);

        if (field) {
          var isTimeField = field.get('dataType') === 'time';

          if (isTimeField) {
            this.set('editedValue', {
              value: null,
              unit: null
            });
          } else {
            this.set('editedValue', null);
          }

          this.setValueFieldDetails();
          this.fetchFieldOptionsHelper();
        } else {
          this.resetEditedValues();
          this.setValueFieldDetails();
        }
      },
      removeFilter: function removeFilter() {
        var fieldId = this.get('filter.field');
        this.removeFilter(fieldId);
      },
      fetchFieldOptions: function fetchFieldOptions() {
        if (!this.get('isAdd')) {
          this.fetchFieldOptionsHelper();
        }
      },
      onTimeUnitChange: function onTimeUnitChange(unit) {
        this.set('editedValue.unit', unit);
      },
      onDateSelect: function onDateSelect(date) {
        date = (0, _date.convertDateToApiFormat)(date);
        this.set('editedValue', date);
      },
      onFromDateSelect: function onFromDateSelect(date) {
        date = (0, _date.convertDateToApiFormat)(date);
        this.set('editedValue.from', date);
      },
      onToDateSelect: function onToDateSelect(date) {
        date = (0, _date.convertDateToApiFormat)(date);
        this.set('editedValue.to', date);
      },
      searchAutocomplete: function searchAutocomplete(term) {
        var selectedField = this.get('selectedField') || this.get('field');
        var visualizationId = this.get('visualizationId');
        return this.get('dataService').getFieldOptions(selectedField, visualizationId, term).then(function (response) {
          return response.results;
        });
      },
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      }
    }
  });

  _exports.default = _default;
});