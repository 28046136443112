define("happyfox-bi/data-sources/components/on-demand-sync-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1MUniF37",
    "block": "{\"symbols\":[\"@wrapperClasses\",\"@status\",\"@dataSourceId\",\"@buttonClasses\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,5],[\"data-sources_on-demand-sync-container\",[30,[36,4],[[35,3],\"tooltipped tooltipped-left\"],null],[32,1]],null]]]],[16,\"aria-label\",[31,[[30,[36,4],[[35,3],[30,[36,1],[\"on-demand-sync-in-progress\"],null]],null]]]],[4,[38,6],[[32,0,[\"updateSyncStatus\"]],[32,3],[32,2]],null],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[[30,[36,5],[\"data-sources_on-demand-sync\",[32,4]],null],\" js-sync-button\"]]],[16,\"disabled\",[32,0,[\"syncInProgress\"]]],[4,[38,7],[\"click\",[32,0,[\"showConfirmationDialogBeforeSync\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,8],[\"refresh-cw\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"canShowConfirmationDialog\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"confirmationDialogTrigger\",\"confirmationDialogClass\",\"stopPropagation\",\"message\",\"primaryActionText\",\"secondaryActionText\",\"primaryAction\",\"secondaryAction\"],[\"sync-button\",\"mod-force-sync-confirmation\",true,[32,0,[\"confirmationMessage\"]],[30,[36,1],[\"yes\"],null],[30,[36,1],[\"cancel\"],null],[30,[36,0],[[32,0],\"initiateSync\",[32,0,[\"forceSync\"]]],null],[30,[36,0],[[32,0],\"hideConfirmationDialog\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"confirmation-dialog\",\"canShowTooltip\",\"if\",\"concat-strings\",\"did-update\",\"on\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "happyfox-bi/data-sources/components/on-demand-sync-button/template.hbs"
    }
  });

  _exports.default = _default;
});