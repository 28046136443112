define("happyfox-bi/data-sources/components/sync-status-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HxTERmvT",
    "block": "{\"symbols\":[\"@status\"],\"statements\":[[2,\"  \"],[11,\"section\"],[16,0,[31,[[30,[36,1],[[32,0,[\"showBanner\"]],[30,[36,4],[\"data-sources_sync-status data-sources_sync-in-progress-message\",[32,0,[\"cssClasses\"]]],null]],null]]]],[4,[38,5],[[32,0,[\"onStatusChange\"]],[32,1]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"showBanner\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"isSyncInProgress\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"async-button\",[],[[\"@class\",\"@text\",\"@inProgress\",\"@inProgressText\",\"@onClick\"],[\"data-sources_sync-status-refresh\",[30,[36,0],[\"refresh-sync-status\"],null],[32,0,[\"inProgress\"]],[30,[36,0],[\"refreshing\"],null],[30,[36,2],[[32,0],\"refreshSyncStatus\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isSyncComplete\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"on-demand-sync-success-message\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"on-demand-sync-failure-message\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"data-sources_sync-status-close\"],[4,[38,2],[[32,0],\"hideBanner\"],null],[12],[2,\"\\n          \"],[1,[30,[36,3],[\"close-icon\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"inline-svg\",\"concat-strings\",\"did-update\"]}",
    "meta": {
      "moduleName": "happyfox-bi/data-sources/components/sync-status-banner/template.hbs"
    }
  });

  _exports.default = _default;
});